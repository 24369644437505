@import '../../styles/variables';

.footerBtn {
  background: $green100;
  color: white;
  width: auto;
  font-weight: 600;
  padding: 8px 32px;
  border-radius: 30px;
}
