.sortArrowUp {
  transform: rotate(90deg);
}

.sortArrowDown {
  transform: rotate(-90deg);
}

.arrowContainer {
  .sortArrowDown,
  .sortArrowUp {
    margin-left: 16px;
  }
}
