@import '../../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}
.heatingDeviceMainContainer {
  height: 100%;
  overflow-y: auto;
  .profitCalculation {
    margin-top: auto;
  }

  .headerTitle {
    font-size: unit(18px);
    font-weight: 700;
  }
  .headerContainer {
    margin-bottom: 32px;
    > div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .cardMainContainer {
    > div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .priceFieldMainContainer {
    margin-top: 32px;

    .headerTitle {
      margin-bottom: 16px;
    }

    .flexContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
      // > div {
      //   margin-bottom: 16px;
      // }
    }
  }
}

@media (min-width: $width768) {
  .heatingDeviceMainContainer {
    .headerTitle {
      font-size: unit(24px);
      font-weight: 700;
    }
    .headerContainer {
      margin-bottom: 32px;
      > div {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 16px;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }

    .cardMainContainer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 16px;
      > div {
        margin-bottom: 16px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    .priceFieldMainContainer {
      .flexContainer {
        // > div {
        //   // flex: 0 0 70%;
        //   // max-width: 70%;
        //   // padding-right: 16px;
        // }
      }
    }
  }
}

@media (min-width: $width992) {
  .heatingDeviceMainContainer {
    .priceFieldMainContainer {
      .flexContainer {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        // > div {
        //   flex: 0 0 48%;
        //   max-width: 48%;
        //   // padding-right: 16px;
        // }
      }
    }
  }
}
