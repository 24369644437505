@import '../../styles/variables';

.headerMainContainer {
  background: $gray25;
  height: $mobHeaderHeight;
  display: grid;
  align-content: center;
  // position: relative;

  .headerContainer {
    > div {
      &:nth-child(1) {
        margin-right: 16px;
        width: calc(100% - 48px);
      }

      &:nth-child(2) {
        flex: 0 0 48px;
        max-width: 48px;
      }
    }
  }
}

@media (min-width: $width768) {
  .headerMainContainer {
    height: $desktopHeaderHeight;

    .headerContainer {
      > div {
        &:nth-child(1) {
          margin-right: 0;
          width: auto;
        }

        &:nth-child(2) {
          flex: 0 0 auto;
          max-width: 100%;
        }
      }
    }
  }
}
