@import '../../../../styles/variables';

.basicDetailsMainContainer {
  margin-top: 64px;
  .basicDetailsContainer {
    margin-bottom: 32px;
    .basicDetailsTitle {
      font-weight: 700;
      font-size: unit(18px);
      margin-bottom: 16px;
    }

    .inputContainer {
      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:not(:nth-child(2), :nth-child(3), :nth-child(5)) {
          > div {
            &:nth-child(2) {
              background: white;
            }
          }
        }
      }
    }
  }

  .textAreaContainer {
    .textAreaTitle {
      font-weight: 700;
      font-size: unit(18px);
      margin-bottom: 16px;
    }

    .textArea {
      background: $gray25;
      border-radius: 18px;
      padding: 16px;
    }
  }
}

@media (min-width: $width768) {
  .basicDetailsMainContainer {
    .basicDetailsContainer {
      margin-bottom: 0;
      flex: 0 0 50%;
      max-width: 50%;
      margin-right: 32px;
    }

    .textAreaContainer {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}
