@import '../../styles/variables';

.navMenuContainer {
  // position: relative;
  .openCloseContainer {
    width: 48px;

    .openCloseIcon {
      fill: $green100;
    }
  }

  .menuListMainContainer {
    position: absolute;
    top: 103%;
    right: 0;
    z-index: 999;
    width: 237px;
    // border-radius: 14px;
    overflow-y: scroll;
    height: calc(100vh - #{$mobHeaderHeight + 71px});
    background: #ffffff;
    box-shadow: 6px 12px 44px rgba(0, 0, 0, 0.09);

    .menuTitleContainer {
      .menuTitleLink {
        font-size: unit(24px);
        display: block;
        font-weight: 500;
        color: inherit;
        border-bottom: 1px solid lighten($black50, 50%);
        padding: 14px 16px;

        &.active {
          color: $green100;
        }
      }
    }

    .menuList {
      padding-top: 16px;
      height: 100%;
    }
  }
}

@media (min-width: $width768) {
  .navMenuContainer {
    position: relative;
    .headerMainContent {
      .headerContent {
        .contentLinkContainer {
          margin-right: 32px;

          .contentLink {
            color: $black50;
            font-size: unit(20px);
            font-weight: 600;

            &:first-child {
              margin-right: 32px;
            }

            &.active {
              color: $green100;
            }
          }
        }

        .dropDownContainer {
          .dropDownBtn {
            background: white;
            font-weight: 400;
            font-size: unit(16px);
            padding: 8px 16px;
            border-radius: 30px;

            .dropDownBtnIcon {
              width: 16px;
              fill: $green100;
              margin-right: 16px;
            }

            .arrowIcon {
              width: 12px;
              margin-left: 8px;
            }
          }
        }
      }
    }

    .menuListMainContainer {
      // height: calc(100vh - #{$desktopHeaderHeight + 80px});
      height: auto;
      border-radius: 14px;
      overflow: hidden;

      .menuList {
        // height: 100%;
        padding-top: 16px;
      }
    }
  }
}
