@import '../../../styles/variables';

.deviceContainer {
  .deviceItemsContainer {
    background: $gray25;
    padding: 16px;
    border-radius: 14px;
    border: 1.5px solid transparent;

    > div {
      &:nth-child(1) {
        width: calc(100% - 70px);
        .deviceItemsText {
          color: $black50;
          font-size: unit(16px);
          font-weight: 700;
        }
      }
      &:nth-child(2) {
        flex: 0 0 70px;
        max-width: 70px;
      }
    }
  }

  .btnContainer {
    margin-top: 16px;
    .deviceBtn {
      background: $green100;
      color: white;
      padding: 4px 0;
      font-weight: 600;
      border-radius: 30px;

      &:nth-child(1) {
        margin-bottom: 8px;
      }
    }
  }

  &.activeItems {
    .deviceItemsContainer {
      background: lighten($green100, 40%);
      border-color: $green100;
    }
  }

  &.disabledItems {
    cursor: not-allowed;
    .deviceItemsContainer {
      opacity: 0.5;
    }

    .btnContainer {
      .deviceBtn {
        opacity: 0.2;
        &:nth-child(1) {
          margin-bottom: 8px;
        }

        &.selectedBtn {
          background: $gray75;
          opacity: 1;
        }
      }
    }
  }
}

.product {
  background: $gray25;
  border-radius: 30px;
  padding: 8px;
  font-size: unit(16px);
  color: $black50;
  border: 1.5px solid transparent;

  // span {
  //   font-weight: 700;
  // }
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.activeItems {
    background: lighten($green100, 40%);
    border-color: $green100;
    color: $green100;
  }
}

.heatingDesktopTitle {
  font-weight: 700;
  font-size: unit(24px);
  margin-bottom: 32px;
}

.heatingDeviceContainer {
  .heatingTitle {
    color: $black50;
    font-weight: 700;
    font-size: unit(18px);
    margin-bottom: 16px;
  }

  .heatingDeviceContent {
    > div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .productContainer {
    .productTitleContainer {
      font-weight: 700;
      font-size: unit(18px);
      color: $black50;
      margin: 16px 0;
    }
  }
}

@media (min-width: $width768) {
  .heatingDeviceContainer {
    .heatingDeviceContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 16px;

      > div {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
    .product {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .productContainer {
      .productDeviceContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        row-gap: 8px;
      }
    }
  }
}

@media (min-width: $width1200) {
  .heatingDeviceContainer {
    .heatingDeviceContent {
      grid-template-columns: repeat(3, 1fr);
    }

    .productContainer {
      .productDeviceContent {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
