@import '../../styles/variables';

.labelContainer {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  background: white;
  border-radius: 30px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      margin-bottom: 8px;
      > div {
        padding-left: 16px;
        &:nth-child(1) {
          font-weight: 500;
          font-size: unit(14px);
        }
      }
    }

    &:nth-child(3) {
      > div {
        padding: 0;
        > div {
          &:nth-child(2) {
            input {
              background: $gray25;
            }
          }
        }
      }
    }
  }
}

.labelTitleContainer {
  font-size: unit(16px);
  font-weight: 700;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;

  .labelIdContainer {
    font-weight: normal;
  }

  .imageContainer {
    flex: 0 0 70px;
    max-width: 70px;
  }
}
.priceFieldContainer {
  background: $gray25;
  border-radius: 14px;
  padding: 8px;
  .contentContainer {
    .vatFieldContainer {
      display: none;
    }
    > div {
      &:first-child {
        background: white;

        > div {
          &:nth-child(2) {
            background: $gray25;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: 480px) {
  .labelContainer {
    > div {
      // &:nth-child(2) {
      //   width: 50%;
      // }
    }
  }
}

@media (min-width: $width576) {
  .labelContainer {
    > div {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      &:nth-child(1) {
        // flex: 0 0 50%;
        // max-width: 50%;
        margin-bottom: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 0;
        // flex: 0 0 25%;
        // max-width: 25%;
      }
    }
  }

  .priceFieldContainer {
    .contentContainer {
      .vatFieldContainer {
        display: flex;
        justify-content: flex-end;

        > div {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
      }
    }
  }
}
