@import '../../../styles/variables';

.dropDownContainer {
  background: $gray25;
  border-radius: 18px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      margin-bottom: 8px;

      p {
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      width: 80%;
      margin-left: auto;
      background-color: white;
      border-radius: 30px;
    }
  }
}

@media (min-width: 480px) {
  .dropDownContainer {
    // border-radius: 30px;

    > div {
      // &:nth-child(1) {
      //   flex: 0 0 65%;
      //   max-width: 65%;
      //   margin-bottom: 0;
      // }

      &:nth-child(2) {
        // width: 100;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

@media (min-width: $width576) {
  .dropDownContainer {
    border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 65%;
        max-width: 65%;
        margin-bottom: 0;
      }

      &:nth-child(2) {
        width: 100;
        flex: 0 0 35%;
        max-width: 35%;
      }
    }
  }
}

@media (min-width: $width768) {
  .dropDownContainer {
    // border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 60%;
        max-width: 60%;
        // margin-bottom: 0;
      }

      &:nth-child(2) {
        width: 100;
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
}
