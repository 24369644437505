@import '../../../styles/variables';

.footerContainer {
  > div {
    &:first-child {
      margin-right: 16px;
    }
  }
}

.addWellBtnContainer {
  margin-top: 32px;
  .addWellbtn {
    background: $gray25;
    color: $green100;
  }
}
.textControlContainer {
  background: $gray25;
  border-radius: 18px;
  padding: 8px;
  > div {
    &:nth-child(1) {
      p {
        font-weight: 500;
      }
    }
  }
  textarea {
    border: 0;
    border-radius: 18px;
    width: 100%;
    padding: 16px;
    &:focus {
      outline: 0;
    }
  }
}

.drillingTeamViewMainContainer {
  height: 100%;
  .titleContainer {
    font-size: unit(18px);
    font-weight: 700;
    margin-bottom: 8px;
  }

  .gridContaner {
    .drillingDepthContainer {
      margin-bottom: 32px;
      .depthInputContainer {
        > div {
          > div {
            > div {
              &:nth-child(2) {
                background: white;
              }
            }
          }
        }
      }
    }
    .siteInforMainContainer {
      margin-bottom: 32px;
      .siteInfoContainer {
        > div {
          padding: 16px;
          margin-bottom: 8px;
          background: $gray25;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: unit(14px);
        }
      }
    }

    .commentMainContainer {
      margin-bottom: 32px;
      .textArea {
        background: $gray25;
        border-radius: 18px;
        border: 0;
        width: 100%;
        padding: 16px;

        &:focus {
          outline: 0;
        }
      }
    }
  }
  .flexContaner {
    .theWellContainer,
    .collectorContainer {
      margin-bottom: 32px;
      .inputContainer {
        > div {
          > div {
            &:nth-child(2) {
              background: white;
            }
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &:not(:nth-child(2), :nth-child(3), :nth-child(5)) {
            > div {
              &:nth-child(2) {
                background: white;
              }
            }
          }
        }
      }
    }
  }

  .signatureAndCompanyContainer {
    .signBtnContainer {
      .btnContainer {
        .signBtn {
          background: $green100;
          color: white;
          font-weight: 600;
          border-radius: 30px;
          padding: 8px 32px;
          margin-bottom: 16px;
          .signBtnIcon {
            width: 14px;
            fill: white;
            margin-left: 16px;
          }
        }
      }
    }
    > div {
      > div {
        a {
          color: $black50;
        }
      }
    }
  }
}

.dropDownContainer {
  background: $gray25;
  border-radius: 18px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      margin-bottom: 8px;

      p {
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      width: 80%;
      margin-left: auto;
      background-color: white;
      border-radius: 30px;
    }
  }
}

@media (min-width: 480px) {
  .dropDownContainer {
    // border-radius: 30px;

    > div {
      // &:nth-child(1) {
      //   flex: 0 0 65%;
      //   max-width: 65%;
      //   margin-bottom: 0;
      // }

      &:nth-child(2) {
        // width: 100;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .signBtnContainer {
    display: flex;
    justify-content: space-between;
    .btnContainer {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}

@media (min-width: $width576) {
  .dropDownContainer {
    border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 65%;
        max-width: 65%;
        margin-bottom: 0;
      }

      &:nth-child(2) {
        width: 100;
        flex: 0 0 35%;
        max-width: 35%;
      }
    }
  }
  .signBtnContainer {
    display: flex;
    justify-content: space-between;
    .btnContainer {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}

@media (min-width: $width768) {
  .drillingTeamViewMainContainer {
    .titleContainer {
      font-size: unit(24px);
      font-weight: 700;
    }
    .gridContaner {
      display: grid;
      column-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }
    .flexContaner {
      .theWellContainer {
        flex: 0 0 40%;
        margin-right: 32px;
        max-width: 40%;
      }
      .collectorContainer {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
    .signatureAndCompanyContainer {
      margin-top: auto;
    }
  }

  .dropDownContainer {
    // border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 60%;
        max-width: 60%;
        // margin-bottom: 0;
      }

      &:nth-child(2) {
        width: 100;
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
  .signBtnContainer {
    display: flex;
    justify-content: space-between;
    .btnContainer {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}
