@import '../../../styles/variables';

.siteMemoMainContainer {
  // padding-bottom: 64px;
  .headerContainer {
    .titleContainer {
      font-size: unit(18px);
      font-weight: 700;
    }
  }

  .formMainContainer {
    margin-top: 32px;
  }
}

@media (min-width: $width768) {
  .siteMemoMainContainer {
    .headerContainer {
      padding-right: 16px;
      .titleContainer {
        font-size: unit(24px);
      }
    }
  }
}
