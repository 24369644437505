@import '../../styles/variables';

.cardContainer {
  background: $white75;
  border-radius: 14px;
  padding: 16px;
  border: 1px solid transparent;

  &.activeCard {
    border-color: $green100;
    background: lighten($green100, 40%);
  }

  &.disabledCard {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > div {
    &:nth-child(1) {
      font-size: unit(16px);
      font-weight: 700;
      width: calc(100% - 40px);
      padding-right: 16px;
    }

    &:nth-child(2) {
      flex: 0 0 40px;
      max-width: 40px;

      .imgFluidOverride {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: $width768) {
  .cardContainer {
    text-align: center;
    > div {
      &:nth-child(1) {
        width: auto;
        padding-right: 0;
        order: 1;
      }

      &:nth-child(2) {
        order: 0;
        flex: 0 0 auto;
        max-width: 100%;
        width: 40%;
        height: 150px;
      }
    }
  }
}
