@import '../../../styles/variables';

.arrowBtn {
  padding: 0 8px;
  height: 100%;

  .arrowBtnIcon {
    display: block;
  }
}

.numberControlContainer {
  background: $gray25;
  // border-radius: 30px;
  border-radius: 18px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 8px;

      p {
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;
      position: relative;
      overflow: hidden;
      border-radius: 30px;

      .inputField {
        background: transparent;
        // padding-left: 16px;
        padding: 4px 16px;
        padding-right: 70px;
        font-size: unit(14px);
        font-weight: 500;
        color: $black75;
      }
      .disableInput {
        background: #f7f7f5;
        cursor: inherit !important;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .centimeter {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 64px;
        font-size: unit(14px);
        font-weight: 500;
        color: $black75;
      }

      .arrowBtnContainer {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background: transparent;

        > div {
          width: 28px;
          border-left: 1px solid $gray25;
        }
      }
    }
  }
  // hidden Arrow condition
  &.hiddenArrow {
    > div {
      &:nth-child(2) {
        .inputField {
          padding-right: 0;
        }

        .centimeter {
          right: 16px;
        }
      }
    }
  }
  // This class is to remove the label
  &.noLabelControl {
    background-color: transparent;
    > div {
      &:nth-child(1) {
        display: none;
        flex: 0 0 auto;
        max-width: 100%;
      }

      &:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        .inputField {
          padding-right: 70px;
        }
        .centimeter {
          right: 50px;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .numberControlContainer {
    > div {
      &:nth-child(2) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
@media (min-width: $width576) {
  .numberControlContainer {
    border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 65%;
        max-width: 65%;
        margin-bottom: 0;
      }

      &:nth-child(2) {
        flex: 0 0 35%;
        max-width: 35%;
      }
    }
  }
}

@media (min-width: $width768) {
  .arrowBtn {
    height: 100%;
  }

  .numberControlContainer {
    > div {
      &:nth-child(1) {
        flex: 0 0 60%;
        max-width: 60%;
      }

      &:nth-child(2) {
        flex: 0 0 40%;
        max-width: 40%;
        .centimeter {
          right: 38px;
        }

        .inputField {
          padding-right: 45px;
        }

        .arrowBtnContainer {
          top: 0;
          transform: translateY(0);
          right: 0;
          bottom: 0;
          width: 28px;

          > div {
            position: absolute;
            height: 50%;
            width: 100%;
            top: 0;
            right: 0;
            border-left: 1px solid $gray25;

            &:nth-child(2) {
              top: auto;
              bottom: 0;
            }
          }
        }
      }
    }
    &.noLabelControl {
      > div {
        &:nth-child(2) {
          .inputField {
            padding-right: 45px;
          }
          .centimeter {
            right: 32px;
          }
        }
      }
    }
  }
}
