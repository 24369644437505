@import '../../../styles/variables';

.headerContainer {
  margin-bottom: 32px;
  .logoContainer {
    width: 180px;
    margin-bottom: 16px;
    .logoImg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .detailsContainer {
    > div {
      font-size: unit(10px);
      .boldText {
        font-weight: 600;
      }
      &:nth-child(1) {
        padding-right: 32px;
      }
    }
  }
}

.billContainer {
  > div {
    font-size: unit(12px);
    &:nth-child(1) {
      border-bottom: 1px solid $gray75;
      padding-bottom: 16px;

      p {
        font-weight: 600;
      }
    }

    &:nth-child(2) {
      margin-top: 16px;
      p:nth-child(1) {
        font-weight: 600;
      }
    }
  }
}

.billFlexContainer {
  margin-bottom: 100px;

  > div {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.billContentContainer {
  .tableContainer {
    border: 1px solid $gray75;
    border-radius: 12px;
    font-size: unit(12px);
    .tableFlexContainer {
      > div {
        padding: 8px;
        flex: 0 0 25%;
        border-bottom: 1px solid $gray75;

        > div {
          border-right: 1px solid $gray75;
          padding-right: 8px;
        }

        &:last-child {
          > div {
            border-right: 0;
          }
        }
      }
    }

    .tableFlexHeader {
      font-weight: 600;
    }

    .tableFlexData:last-child {
      > div {
        border-bottom: 0;
      }
    }
  }

  .totalContainer {
    text-align: right;
    padding: 8px 16px;
    font-size: unit(18px);
    font-weight: 700;
    color: $green100;

    span {
      font-size: unit(14px);
      font-weight: 600;
      color: $black50;
      margin-right: 32px;
    }
  }
}

.billParentContainer {
  height: 100%;
  overflow-y: auto;
  .billMainContainer {
    border-radius: 18px;
    border: 1px solid $gray25;
    padding: 16px;
    height: 100%;
    .billFooterContainer {
      margin-top: auto;
      > div {
        &:nth-child(1) {
          flex: 0 0 32px;
          margin-right: 16px;
        }

        &:nth-child(2) {
          font-size: unit(12px);
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .headerContainer {
    .detailsContainer {
      > div {
        font-size: unit(12px);
      }
    }
  }

  .billContainer {
    > div {
      font-size: unit(14px);
    }
  }
  .billContentContainer {
    .tableContainer {
      font-size: unit(14px);
    }
  }
}

@media (min-width: $width768) {
  .billParentContainer {
    .titleContainer {
      font-weight: 600;
      font-size: unit(24px);
      margin-bottom: 32px;
    }
    .billMainContainer {
      border: 1px solid $green100;
      border-radius: 8px;
      position: relative;
      padding-top: 80px;
      height: auto;
      .titleContainer {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        font-weight: 600;
        font-size: unit(24px);
        margin-bottom: 0;
        background: lighten($green100, 30%);
        color: $green100;
        text-align: center;
        padding: 16px 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      // .billFooterContainer {
      //   margin-top: auto;
      //   > div {
      //     &:nth-child(1) {
      //       flex: 0 0 32px;
      //       margin-right: 16px;
      //     }

      //     &:nth-child(2) {
      //       font-size: unit(12px);
      //     }
      //   }
      // }
    }
  }
}

@media (min-width: $width992) {
  .billParentContainer {
    width: 85%;
  }
}

@media (min-width: $width1200) {
  .billParentContainer {
    width: 70%;
  }
}

@media (min-width: $width1440) {
  .billParentContainer {
    width: 70%;
  }
}
