@import '../../../styles/variables';

.addNewWorkerPageContainer {
  padding-top: 32px;
  .workerFormMainContainer {
    margin-bottom: 64px;
    .workerFormTitleContainer {
      margin-bottom: 32px;
      .workerFormTitle {
        font-weight: 700;
        font-size: unit(16px);
      }
    }

    .workerFormContainer {
      > div:not(:last-child) {
        margin-bottom: 16px;

        > div {
          background: transparent;
          > div {
            &:nth-child(2) {
              background: $gray25;
              border-radius: 30px;
              border: 1px solid $black50;
              input {
                background: transparent;
              }
            }
          }
        }
      }

      .btnContainer {
        width: 200px;
        margin-left: auto;
      }
    }
  }

  .workerListContainer {
    .workerListTitleContainer {
      font-size: unit(16px);
      font-weight: 700;
      margin-bottom: 32px;
    }

    .subListContainer {
      > div {
        > div {
          font-size: unit(14px);
          font-weight: 500;
          background: $gray25;
          border-radius: 32px;
          padding: 8px 16px;
          display: inline-block;
        }
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: $width576) {
  .addNewWorkerPageContainer {
    .workerFormMainContainer {
      .workerFormContainer {
        > div:not(:last-child) {
          > div {
            background: transparent;
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: $width768) {
  .addNewWorkerPageContainer {
    .workerFormMainContainer {
      .workerFormTitleContainer {
        .workerFormTitle {
          font-size: 24px;
        }
      }
    }

    .workerListContainer {
      .workerListTitleContainer {
        font-size: unit(24px);
      }
    }
  }
}

@media (min-width: $width1200) {
  .addNewWorkerPageContainer {
    .workerFormMainContainer {
      .workerFormContainer {
        width: 70%;
      }
    }
  }
}
