@import '../../../styles/variables';

.calendarControlContainer {
  background: $gray25;
  padding: 8px;
  border-radius: 18px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .calendarControlLabel {
    font-weight: 500;
    font-size: unit(16px);
    margin-bottom: 16px;
  }

  .calendarContainer {
    background: rgb(255, 255, 255);
    border-radius: 30px;
    width: 80%;
    margin-left: auto;
    padding: 8px;
  }
}

@media (min-width: 480px) {
  .calendarControlContainer {
    .calendarContainer {
      width: 50%;
    }
  }
}

@media (min-width: $width576) {
  .calendarControlContainer {
    border-radius: 30px;
    .calendarControlLabel {
      width: 65%;
      margin-bottom: 0;
    }

    .calendarContainer {
      width: 35%;
    }
  }
}
@media (min-width: $width768) {
  .calendarControlContainer {
    .calendarControlLabel {
      margin-bottom: 0;
      width: 60%;
    }

    .calendarContainer {
      width: 40%;
    }
  }
}
