@import '../../../styles/variables';

.installingCrewMainContainer {
  height: 100%;

  .titleContainer {
    font-size: unit(18px);
    font-weight: 700;
    margin-bottom: 8px;
  }

  .selectedCrewContainer {
    .selectedCrewFlexContainer {
      .installingCrewFieldMainContainer {
        // margin-bottom: 32px;
        .installingCrewFieldContainer {
          background: $gray25;
          padding: 8px;
          border-radius: 14px;
          overflow: hidden;
          // margin-bottom: 32px;
        }
      }
    }
    margin-top: 32px;
    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.installingCrewFieldMainContainer {
  // margin-bottom: 32px;
  .installingCrewFieldContainer {
    background: $gray25;
    padding: 8px;
    border-radius: 14px;
    overflow: hidden;
    // margin-bottom: 32px;

    .headerContainer {
      margin-bottom: 16px;
      > div {
        &:nth-child(1) {
          width: calc(100% - 80px);
          padding-right: 16px;
          word-break: break-all;
          .headerName {
            font-weight: 700;
          }
        }
        &:nth-child(2) {
          width: 80px;
        }
      }
    }

    .contentContainer {
      > div {
        background: white;
        border-radius: 18px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:nth-child(1) {
          > div {
            &:nth-child(2),
            &:nth-child(3) {
              > div {
                > div {
                  background: $gray25;
                }
              }
            }
          }
        }

        &:nth-child(3) {
          > div {
            &:nth-child(2) {
              background: $gray25;
            }
          }
        }
      }
    }

    .installingInputFields {
      > div {
        background: white;
        border-radius: 32px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        &:last-child {
          > div {
            &:last-child {
              background-color: $gray25;
            }
          }
        }
        &:first-child {
          > div {
            &:nth-child(2) {
              background: $gray25;
              > div {
                &:first-child {
                  > div {
                    &:first-child {
                      > div {
                        &:first-child {
                          > div {
                            background: $gray25;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-child(2) {
          > div {
            &:last-child {
              background: $gray25;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $width768) {
  .installingCrewMainContainer {
    .titleContainer {
      font-size: unit(24px);
      font-weight: 700;
    }

    .selectedCrewFlexContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
    }
  }
}
