@import '../../../styles/variables';

.appContainer {
  .fixedElement {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 998;
  }

  .projectContent {
    margin-top: $mobHeaderHeight;
    height: calc(100vh - $mobHeaderHeight);
    overflow: hidden;
    // background: red;
  }
}

@media (min-width: $width768) {
  .appContainer {
    .projectContent {
      margin-top: $desktopHeaderHeight;
      height: calc(100vh - $mobHeaderHeight);
    }
  }
}
