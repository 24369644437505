@import '../../styles/variables';

.uploadBtnContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  display: grid;
  align-content: center;
  border-radius: 8px;
  // margin-bottom: 16px;
}

.uploadBtnContainer .uploadBtn {
  color: inherit;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
}

.uploadBtnContainer input[type='file'] {
  font: inherit;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.uploadBtnContainer input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
.cameraContainer {
  margin: 0;
  .uploadBtnContainer {
    background-color: white;
    padding: 16px;
  }
}
