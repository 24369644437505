@import '../../styles/variables';

.accordionTabsContainer {
  margin-bottom: 32px;

  .accordionBtn {
    padding: 8px 0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -16px;
      right: -16px;
      height: 1.5px;
      background: $gray25;
    }

    .btnTitle {
      font-weight: 600;
      font-size: unit(16px);
      color: $green100;
    }
  }

  .tabContentContainer {
    .accordionBtn {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -16px;
        right: -16px;
        height: 100%;
        z-index: -1;
        background: $gray25;
        border-bottom: 1px solid $gray75;
      }
    }
  }
}
