@import '../../../styles/variables';
.filterDropDownParent {
  div {
    > div {
      &:nth-child(2) {
        right: 0;
        left: auto;
        min-width: 250px;
      }
    }
  }
}
.taskTitle {
  font-size: unit(20px);
  font-weight: 700;
}

.taskPageContainer {
  padding-top: 16px;

  .headerContainer {
    margin-bottom: 16px;

    > div {
      &:nth-child(2) {
        margin-top: 16px;
        white-space: nowrap;

        .filterTitle {
          font-weight: 600;
          margin-right: 12px;
        }
        .filterDropDown {
          background-color: $gray25;
        }
        > div {
          &:nth-child(1) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media (min-width: $width768) {
  .taskTitle {
    font-size: unit(32px);
  }
  .taskPageContainer {
    .headerContainer {
      margin-bottom: 16px;

      > div {
        &:nth-child(2) {
          margin-top: 0;

          > div {
            &:nth-child(1) {
              margin-right: 16px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
