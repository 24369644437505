@import '../../styles/variables';

.customTimeContainer {
  background: $white75;
  padding: 32px 16px;

  .clockDiv {
    margin-right: 8px;
    .clockContainer {
      // width: 24px;

      width: 14px;
      // margin-right: 8px;
      margin-right: 4px;
      .clockIcon {
        fill: $green100;
      }
    }

    .textContainer {
      font-weight: 500;
      font-size: unit(14px);
    }
  }

  .inputDiv {
    > div {
      // width: 58px;

      &:nth-child(2) {
        width: auto;
        font-weight: 700;
        font-size: unit(24px);
        margin: 0 8px;
      }
      .inputField {
        font: inherit;
        font-family: 'Montserrat', sans-serif;
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: 5px;
        border: 1.8px solid $white50;
        background: white;
        font-size: unit(24px);
        font-weight: 700;
        // padding: 4px 8px;

        padding: 4px;
        color: $black50;
      }
    }
  }
}

@media (min-width: $width576) {
  .customTimeContainer {
    padding: 32px;
    .clockDiv {
      margin-right: 16px;
    }
  }
}
