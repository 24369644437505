@import '../../styles/variables';

.searchBoxContainer {
  position: relative;
  height: 38px;
  z-index: 3;
  // margin: 50px 0;

  .searchInput {
    padding-left: 48px;
    padding-right: 16px;
    height: 100%;

    &:focus {
      border-color: $green100;
    }
  }

  .searchInput:focus + .searchIcon {
    path {
      &:nth-child(1) {
        fill: $green100;
      }

      &:nth-child(2) {
        fill: white;
      }
    }
  }

  .searchIcon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 24px;
    z-index: 3;
  }
}
