@import '../../../styles/variables';

.textControlContainer {
  background: $gray25;
  // border-radius: 30px;
  border-radius: 18px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 8px;

      p {
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;

      .inputField {
        background: white;
        padding: 4px 16px;
        font-size: unit(14px);
        font-weight: 500;
        color: $black75;
      }
    }
  }
}

@media (min-width: 480px) {
  .textControlContainer {
    // background: $gray25;
    // border-radius: 30px;
    // border-radius: 18px;
    // padding: 8px;

    > div {
      &:nth-child(1) {
        // flex: 0 0 100%;
        // max-width: 100%;
        // margin-bottom: 8px;

        // p {
        //   font-weight: 500;
        // }
      }

      &:nth-child(2) {
        flex: 0 0 50%;
        max-width: 50%;
        // margin-left: auto;

        // .inputField {
        //   background: white;
        //   padding: 4px 16px;
        //   font-size: unit(14px);
        //   font-weight: 500;
        //   color: $black75;
        // }
      }
    }
  }
}
@media (min-width: $width576) {
  .textControlContainer {
    // background: $gray25;
    // border-radius: 30px;
    border-radius: 30px;
    // padding: 8px;

    > div {
      &:nth-child(1) {
        flex: 0 0 65%;
        max-width: 65%;
        margin-bottom: 0;

        // p {
        //   font-weight: 500;
        // }
      }

      &:nth-child(2) {
        flex: 0 0 35%;
        max-width: 35%;
        // margin-left: auto;

        // .inputField {
        //   background: white;
        //   padding: 4px 16px;
        //   font-size: unit(14px);
        //   font-weight: 500;
        //   color: $black75;
        // }
      }
    }
  }
}
@media (min-width: $width768) {
  .textControlContainer {
    // background: $gray25;
    // border-radius: 30px;
    // border-radius: 18px;
    // padding: 8px;

    > div {
      &:nth-child(1) {
        flex: 0 0 60%;
        max-width: 60%;
        margin-bottom: 0;

        // p {
        //   font-weight: 500;
        // }
      }

      &:nth-child(2) {
        flex: 0 0 40%;
        max-width: 40%;
        // margin-left: auto;

        // .inputField {
        //   background: white;
        //   padding: 4px 16px;
        //   font-size: unit(14px);
        //   font-weight: 500;
        //   color: $black75;
        // }
      }
    }
  }
}
