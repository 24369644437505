@import '../../styles/variables';

.menuListContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menuContentContainer {
    .menuBtn {
      color: $black50;
      font-weight: 600;
      text-align: left;
      padding: 16px;

      .menuBtnIcon {
        fill: $black50;
        width: 24px;
        margin-right: 16px;
      }
    }
    .menuBtn:hover {
      .menuName {
        color: $green100;
        .menuBtnIcon {
          fill: $green100;
        }
      }
    }

    .menuAccordion {
      .menuAccordionItem {
        border: 0;

        .menuAccordionHeader {
          font-size: unit(16px);

          button {
            padding: 16px;
            font-size: unit(16px);
            font-weight: 600;
            color: $black50;

            .menuAccordionIcon {
              fill: $black50;
              width: 24px;
              margin-right: 16px;
            }

            &:focus {
              outline: 0;
              box-shadow: none;
              background: transparent;
            }
          }
        }
        .menuAccordionHeader:hover {
          .menuName {
            color: $green100;
            .menuAccordionIcon {
              fill: $green100;
            }
          }
        }

        .menuAccordionBody {
          padding: 0;

          .menuAccordionBtn {
            color: $black50;
            font-weight: 500;
            font-size: unit(14px);
            padding: 16px;
            display: block;
            text-align: left;
            border-bottom: 1px solid lighten($black50, 50%);

            &:first-child {
              border-top: 1px solid lighten($black50, 50%);
            }
          }
          .menuAccordionBtn:hover {
            color: $green100;
          }
        }
      }
    }
  }

  .logOutBtnContainer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 100;

    .logOutBtn {
      color: $black50;
      padding: 16px;
      border-top: 1px solid lighten($black50, 50%);
      background: #ffffff;
      box-shadow: 6px 12px 44px rgba(0, 0, 0, 0.09);

      .logOutBtnIcon {
        opacity: 0.5;
        width: 16px;
        display: block;
      }
    }
    .logOutBtn:hover {
      .logOutMenu {
        color: $green100;
      }
    }
  }
}

@media (min-width: $width768) {
  .menuListContainer {
    height: auto;

    .logOutBtnContainer {
      position: static;
      bottom: auto;
      left: auto;
      right: auto;
    }
  }
}
