@import '../../../styles/variables';

.section1Container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.accordion {
  width: 100%;

  .accordionItem {
    border-left: none;
    border-right: none;
    border-radius: 0;

    .accordionHeader {
      outline: none;

      .accordionHeaderContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 10px;
      }

      .accordionHeaderText {
        font-weight: 700;
        font-size: unit(18px);
        color: $black50;
      }
    }
    // .accordionBody {
    //   //
    // }
  }
}

.footerContainer {
  column-gap: 10px;
}

.commentContainer {
  // margin-bottom: 32px;
  min-width: 400px;

  .commentTitleContainer {
    margin-bottom: 16px;

    .commentTitle {
      font-weight: 700;
      font-size: unit(18px);
    }
  }

  .commentTextArea {
    background: $gray25;
    border-radius: 18px;
    border: 0;
    width: 100%;
    padding: 16px;

    &:focus {
      outline: 0;
    }
  }
}

.addProductsContainer {
  margin-bottom: 16px;

  .addProductsTitle {
    font-weight: 700;
    font-size: unit(18px);
  }
}

.productTitleContainer {
  margin-bottom: 16px;

  .productTitle {
    font-weight: 700;
    font-size: unit(18px);
  }
}

.productContainer {
  max-width: 650px;
  background-color: $gray25;
  padding: 16px;
  border-radius: 14px;

  .productNameAndBtnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .productIdAndName {
    font-size: 16px;
    font-weight: 500;
  }

  .productName {
    font-weight: 700;
  }

  .removeProductBtn {
    width: fit-content;
    gap: 8px;
    display: flex;
    align-items: center;
    color: $black50;
  }

  .productRowContainer {
    background-color: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 16px;
    margin-top: 8px;

    .productRowTitle {
      font-size: 15px;
    }
    .productRowInputGroup {
      display: flex;
      gap: 5px;
      margin-left: auto;
    }
    .productRowInputContainer {
      background-color: $gray25;
      border-radius: 50px;
      padding: 4px 8px;
    }
    .productRowInput {
      background-color: transparent;
      border: none;
      outline: none;
      width: 75px;
    }
  }
}

.flexContainer {
  position: relative;
}

.productListContainer {
  margin-bottom: 8px;
  .productList {
    overflow: hidden;
    background: $gray25;
    padding: 6px 16px;
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid transparent;
    display: inline-block;
    word-break: break-all;

    .boldText {
      font-weight: 600;
    }
  }
}

.addProductsBtn {
  background-color: #71bf5d29;
  color: #71bf5d;
  max-width: 334px;
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 30px;
}

.section2Container {
  margin-top: 32px;
}

.searchContainer {
  .searchBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .searchResult {
    margin-top: 16px;

    .searchResultBtn {
      background-color: $gray25;
      color: $black50;
      display: inline-block;
      width: fit-content;
      margin-right: 16px;
      margin-bottom: 16px;

      &.active {
        background-color: #71bf5d29;
        color: #71bf5d;
      }
    }
  }
}

.sendProductsBtn {
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: $width768) {
  .section1Container {
    flex-direction: row;
    gap: 64px;
  }

  .searchContainer {
    .searchBoxContainer {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 16px;
    }
  }

  .flexContainer {
    > div {
      margin-right: 16px;
    }
  }

  .productListContainer {
    margin-bottom: 8px;

    .productList {
      // padding: 6px 16px;
      display: block;
    }
  }
}
