@import '../../../styles/variables';

.nextBtn,
.addBtn {
  padding: 8px 32px;
  font-weight: 600;
  border-radius: 30px;
  background: $green100;
  color: white;
  white-space: nowrap;
}
