@import '../../styles/variables';

.statusContainer {
  font-weight: 500;
  font-size: unit(14px);

  &.task {
    color: #915eff;
    background: lighten(#915eff, 25%);
  }

  &.completed {
    color: #71bf5d;
    background: lighten(#66995a, 35%);
  }

  &.offerSent {
    color: #71bf5d;
    background: lighten(#71bf5d, 35%);
  }

  &.offerCalculation {
    color: #71bf5d;
    background: lighten(#71bf5d, 35%);
  }

  &.offerCreation {
    color: #f5c115;
    background: lighten(#f5c115, 40%);
  }

  &.onSiteInspection {
    color: #f5c115;
    background: lighten(#f5c115, 40%);
  }

  &.declined {
    color: #e51111;
    background: lighten(#e51111, 42%);
  }

  &.new {
    color: $black50;
    background: white;
  }
}
