@import '../../styles/variables';

.commentContainer {
  margin-top: 32px;
  .commentHeader {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: unit(18px);
  }
  .commentAreaContainer {
    .commentArea {
      background: $gray25;
      border-radius: 18px;
      border: 0;
      padding: 16px;
    }
  }
}

@media (min-width: $width992) {
  .commentContainer {
    margin-top: 0;
  }

  .selectedServiceContainer {
    > div {
      &:nth-child(1) {
        margin-right: 32px;
        width: 50%;
      }
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
