@import '../../styles/variables';

.mobileCalculations {
  background: $gray25;
  border-radius: 18px;
  padding: 16px;

  .calculationsTitle {
    font-size: unit(14px);
    font-weight: 600;
    margin-bottom: 16px;
  }

  .calculationContainer {
    margin-bottom: 32px;

    &:nth-child(5) {
      border-top: 1px solid $c5c5c5;
      padding-top: 16px;
    }

    .titleContainer {
      font-size: unit(14px);
      font-weight: 600;
      color: $black75;
    }

    .contentContainer {
      .flexContainer {
        > div {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }

        .flexTitle {
          font-weight: 500;
          font-size: unit(12px);
        }

        .flexData {
          font-weight: 500;
          font-size: unit(14px);
          color: $black75;
        }
      }
    }
  }

  .translateContainer {
    margin-bottom: 32px;

    > div {
      color: $black75;

      &:nth-child(1) {
        font-weight: 500;
        font-size: unit(14px);
        margin-bottom: 8px;
      }

      &:nth-child(2) {
        font-weight: 600;
        font-size: unit(14px);
      }
    }

    &.invoiceTotalContainer {
      margin-bottom: 0;

      > div {
        &:nth-child(1) {
          margin-bottom: 0;
        }

        &:nth-child(2) {
          font-size: unit(20px);
        }
      }
    }
  }
}

.desktopCalculations {
  background: $gray25;
  border-radius: 18px;
  padding: 16px 0;

  .contentContainer {
    .flexContainer {
      padding: 12px 0;

      &:not(:last-child) {
        border-bottom: 1.5px solid lighten($gray50, 28%);
      }

      > div {
        flex: 0 0 25%;
        max-width: 25%;
        padding-left: 16px;

        &:not(:last-child) {
          border-right: 1px solid lighten($gray50, 28%);
        }
      }

      .flexTitle {
        font-size: unit(14px);
        font-weight: 600;
      }

      .flexData {
        font-size: unit(14px);
        font-weight: 500;
        color: $black75;
      }
    }
  }

  .translateContainer {
    border-top: 1.5px solid lighten($gray50, 28%);
    padding: 12px 0;

    > div {
      padding-left: 16px;
      color: $black75;
      font-weight: 500;
      font-size: unit(14px);
      flex: 0 0 50%;
      max-width: 50%;

      &:not(:first-child) {
        border-left: 1px solid lighten($gray50, 28%);
      }
    }

    &.invoiceTotalContainer {
      padding-bottom: 0;

      > div {
        &:nth-child(1) {
          color: $black50;
          flex: 0 0 75%;
          max-width: 75%;
        }

        &:nth-child(2) {
          font-weight: 600;
          font-size: unit(16px);
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
  }
}

.houseHoldCalculations {
  .checkboxDiv {
    margin-bottom: 32px;
  }

  .titleDiv {
    margin-bottom: 32px;

    .houseHoldTitle {
      font-size: unit(18px);
      font-weight: 700;
    }
  }

  .mobileViewCalculations {
    display: block;
  }

  .desktopViewCalculations,
  .costEstimate {
    display: none;
  }
}

@media (min-width: $width768) {
  .houseHoldCalculations {
    .mobileViewCalculations {
      display: none;
    }

    .desktopViewCalculations,
    .costEstimate {
      display: block;
    }

    .desktopCalculations {
      width: 80%;
    }

    .costEstimate {
      font-weight: 500;
      font-size: unit(14px);
      color: $c5c5c5;
    }
  }
}

@media (min-width: $width1200) {
  .houseHoldCalculations {
    // .mobileViewCalculations {
    //   display: none;
    // }

    .desktopViewCalculations,
    .costEstimate {
      display: block;
    }

    // .costEstimate {
    //   font-weight: 500;
    //   font-size: unit(14px);
    //   color: $c5c5c5;
    // }
  }

  .desktopCalculations {
    width: 65%;
  }
}
