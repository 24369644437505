@import '../../../styles/variables';

.userItemContainer {
  margin-bottom: 8px;

  .userItem {
    overflow: hidden;
    background: $gray25;
    padding: 6px 16px;
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid transparent;
    display: inline-block;
    word-break: break-all;

    .boldText {
      font-weight: 600;
    }
  }

  &.activeUserItem {
    .userItem {
      background: lighten($green100, 35%);
      color: $green100;
      font-weight: 500;
      border-color: $green100;

      .boldText {
        font-weight: 700;
      }
    }
  }
}
