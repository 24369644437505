@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables';

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: unit(16px);
  color: $black50;
  // overflow: hidden;
}

h1,
h2,
h3,
h4,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

.imgFluid {
  width: 100%;
  height: auto;
  display: block;
}

.inputField {
  background: $gray25;
  border: 1px solid transparent;
  width: 100%;
  font: inherit;
  border-radius: 30px;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
::placeholder {
  color: #c5c5c5;
}
// start of all button design

.projectBtn {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  font: inherit;

  &:focus {
    outline: 0;
  }

  &.createNewBtn {
    font-size: unit(14px);
    color: $green100;
    font-weight: 600;
    background: white;
    border-radius: 30px;
    padding: 14px 16px;
    width: auto;
    margin-left: auto;

    .createNewBtnIcon {
      fill: $green100;
      width: 16px;
      margin-right: 8px;
    }
  }

  &.loadBtn {
    font-size: unit(14px);
    font-weight: 500;
    color: $green100;
    background: lighten($green100, 35%);
    border-radius: 30px;
  }

  &.offerTabFooterBtn {
    border-radius: 30px;
    padding: 8px 32px;
    font-weight: 600;
    &.offerTabGreenBtn {
      background: $green100;
      color: white;
    }

    &.offerTabGrayBtn {
      background: $gray25;
      color: $black50;
    }
    &.offerTabOrangeBtn {
      background: $yellow100;
      color: white;
    }
  }

  &.grayBtn,
  &.greenBtn,
  &.redBtn {
    color: $black50;
    padding: 8px 24px;
    border-radius: 30px;
    font-weight: 600;
  }
  &.grayBtn {
    background: $gray25;
  }
  &.greenBtn {
    background: $green100;
    color: white;
  }
  &.redBtn {
    background: $red100;
    color: white;
  }
}
.logInBtn {
  background-color: $green100;
  border-radius: 40px;
  color: white;
  font-weight: 500;
  padding: 0.75rem;
}
.schedualeBtn {
  background-color: #71bf5d;
  border-radius: 40px;
  color: white;
  font-weight: 500;
  padding: 8px 32px 8px 32px;
  margin: -16px -5px -16px 0;
}

// end of all button design

// overwrite tab css

.tabContainer {
  border: 0;
  padding: 16px 0;

  .nav-item {
    display: block;

    .navLink {
      font-weight: 600;
      width: 100%;
      color: $black50;
      border: 0;
      border-radius: 0;
      padding: 0;
      padding-right: 8px;
      padding-left: 8px;
      font-size: unit(13px);
    }

    .active {
      color: $green100;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &:not(:last-child) {
      .navLink {
        border-right: 1px solid lighten($black50, 10%);
      }
    }

    &:first-child {
      .navLink {
        padding-left: 0;
      }
    }
    &:last-child {
      .navLink {
        padding-right: 0;
      }
    }
  }
}

.signatureTabContainer {
  .tabContainer {
    border-top: 1px solid lighten($black50, 50%);
    border-bottom: 1px solid lighten($black50, 50%);
    padding: 0 16px;
    margin-bottom: 16px;
    .nav-item {
      &:not(:last-child) {
        margin-right: 32px;
      }
      .navLink {
        border-right: 0;
        border-bottom: 2px solid transparent;
        padding: 16px 0;
        font-weight: 500;
        font-size: unit(20px);
      }

      .active {
        border-color: $green100;
      }
    }
  }
}

// container of the project that keep content in the center
.projectContainer {
  padding: 0 16px;
}

// overwrite modal css

.modal-backdrop {
  top: $mobHeaderHeight;
  height: calc(100vh - #{$mobHeaderHeight+$mobHeaderHeight});
  background: rgba(228, 228, 228, 0.2);
  backdrop-filter: blur(17.5px);
}

.modal-backdrop.show {
  opacity: initial;
}

.modal {
  top: $mobHeaderHeight;
  height: calc(100vh - #{$mobHeaderHeight+$mobHeaderHeight});
}

// .modal-dialog {
//   min-width: 275px;
//   margin: 0 auto;
// }

.modal-content {
  border: 0;
  background: #ffffff;
  border-radius: 14px;
}

.modal-header {
  border: 0;
  color: $black50;
  font-size: unit(18px);
  font-weight: 600;
  text-align: center;
  display: block;
}

.modal-footer {
  padding: 32px 16px;
  justify-content: center;
  border-color: $gray25;
}

// all breakpoints
// @media (min-width: $width576) {
//   .modal-dialog {
//     width: 350px;
//   }
// }
@media (min-width: $width768) {
  .modal-backdrop {
    top: $desktopHeaderHeight;
    height: calc(100vh - #{$desktopHeaderHeight+$desktopHeaderHeight});
  }

  .modal {
    top: $desktopHeaderHeight;
    height: calc(100vh - #{$desktopHeaderHeight+$desktopHeaderHeight});
  }
}

@media (min-width: $width992) {
  // overwrite tab css

  .tabContainer {
    .nav-item {
      .navLink {
        padding-right: 16px;
        padding-left: 16px;
        font-size: unit(16px);
      }
    }
  }
}

@media (min-width: $width1200) {
  .projectContainer {
    // padding: 0;
    width: $containerWidth1040;
    margin: 0 auto;
  }
}

@media (min-width: $width1440) {
  .projectContainer {
    width: $containerWidth1140;
  }
}

@media (min-width: $width1920) {
  .projectContainer {
    width: $containerWidth1280;
  }
}

/* start of custom radio  */
.radioContainer,
.circleRadioContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.circleRadioContainer {
  background: $gray25;
  border-radius: 30px;
  margin-bottom: 8px;
  padding: 16px;
  padding-left: 50px;
  text-align: center;
  font-size: unit(14px);
  font-weight: 500;
  // overflow: hidden;
  z-index: 5;
}

.circleRadioContainer .circleActiveBg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: lighten($green100, 40%);
  z-index: -1;
  border-radius: 30px;
  display: none;
}

.radioContainer input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
.radioContainer input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.radioContainer input[type='checkbox']:checked ~ .radioIcon::after,
.radioContainer input[type='checkbox']:checked ~ .radioIcon::before,
.radioContainer input[type='radio']:checked ~ .radioIcon::after,
.radioContainer input[type='radio']:checked ~ .radioIcon::before,
.circleRadioContainer input[type='radio']:checked ~ .radioIcon::before,
.circleRadioContainer input[type='radio']:checked ~ .radioIcon::after,
.circleRadioContainer input[type='radio']:checked ~ .circleActiveBg {
  display: block;
}

.circleRadioContainer input[type='radio']:checked ~ .circleActiveText {
  color: $green100;
}

.radioContainer .radioIcon,
.circleRadioContainer .radioIcon {
  background: white;
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid $gray75;
  border-radius: 50%;
}

.circleRadioContainer .radioIcon {
  background: transparent;
  border-width: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}

.radioContainer .radioIcon::after,
.circleRadioContainer .radioIcon::after {
  content: '';
  z-index: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  border: 1.5px solid $green100;
  background: lighten($green100, 30%);
  display: none;
}

.circleRadioContainer .radioIcon::after {
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 0;
  border: 0;
  background: white;
}

.radioContainer .radioIcon::before,
.circleRadioContainer .radioIcon::before {
  content: '';
  z-index: 2;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid $green100;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}
.circleRadioContainer .radioIcon::before {
  left: 2px;
  top: 2px;
  right: auto;
  bottom: auto;
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 50%;
  background: $green100;
}

/* end of custom radio  */

/* start of file upload input as camera button */

.uploadBtnContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 110px;
  border: 10px solid $gray25;
  border-radius: 18px;
  display: grid;
  align-content: center;
}

.uploadBtnContainer .uploadBtn {
  color: inherit;
  background-color: transparent;

  .cameraIcon {
    width: 45px;
    margin: 0 auto;
  }

  .btnText {
    font-size: unit(14px);
    font-weight: 500;
    margin-top: 5px;
    text-align: center;
  }
}

.uploadBtnContainer input[type='file'] {
  font: inherit;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.uploadBtnContainer input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

/* end of file upload input as camera button */

/* start of custom checkbox  */
.checkBoxContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 28px;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: unit(16px);
}

.checkBoxContainer input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkBoxContainer input[type='checkbox']:checked ~ .checkIcon::after,
.checkBoxContainer input[type='checkbox']:checked ~ .checkIcon::before {
  display: block;
}

.checkBoxContainer .checkIcon {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid $black50;
  border-radius: 4px;
}

.checkBoxContainer .checkIcon::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 0px;
  width: 7px;
  height: 11px;
  z-index: 1;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

.checkBoxContainer .checkIcon::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: $green100;
  border-radius: 4px;
  display: none;
}
.checkBoxContainer .grayCheckBox::before {
  background-color: $gray75;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
}

/* end of custom checkbox  */

/* start of custom slider button  */

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: block;
  font-weight: 500;
  padding-right: 100px;
  min-height: 38px;
}

/* Hide default HTML checkbox */
.switch .switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  width: 80px;
  height: 38px;
  line-height: 38px;
  padding-left: 8px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background-color: $gray25;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-weight: 500;
  font-size: unit(14px);
}

.slider::before {
  position: absolute;
  content: '';
  height: 28px;
  width: 28px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchInput:checked + .slider {
  background-color: $green100;
  color: white;
}

.switchInput:checked + .slider::before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(42px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* end of custom slider button  */

/* start of custom calendar  */
.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0;
}
.react-datepicker-popper {
  inset: 0 !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  transform: translate(0) !important;
  z-index: 999;
  background: rgba(228, 228, 228, 0.2);
  backdrop-filter: blur(17.5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker {
  font-family: 'Noto Sans', sans-serif;
  background: #ffffff;
  box-shadow: 0px 12px 47px rgba(0, 0, 0, 0.101961);
  border: 0;
  border-radius: 12px;
  overflow: hidden;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    width: 100%;
    background: white;
    padding: 8px;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .react-datepicker__day-names {
    margin: 0;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    .react-datepicker__day-name {
      font-size: unit(14px);
      font-weight: 700;
      color: $black50;
      width: auto;
      display: block;
      text-align: center;
      margin: 0;
      padding: 8px;
    }
  }

  .react-datepicker__month {
    margin: 0;
    text-align: left;
    padding: 8px;

    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      align-items: start;
      .react-datepicker__day {
        border: 1.8px solid;
        border-color: transparent;
        border-radius: 6px;
        color: $black50;
        margin: 0;
        display: block;
        width: auto;
        text-align: left;
        font-weight: 700;
        font-size: unit(13px);
        // padding: 0px 8px;
        width: 14.28%;
        text-align: center;
      }

      .react-datepicker__day--keyboard-selected {
        background: rgba(204, 204, 204, 0.258824);
        border-color: $black50;
      }
    }
  }

  .react-datepicker__input-time-container {
    margin: 0;
    background: $white75;
    padding: 0;

    .react-datepicker-time__caption {
      display: block;
    }

    .react-datepicker-time__input-container {
      display: block;
      .react-datepicker-time__input {
        margin: 0;
        display: block;
      }
    }
  }

  .react-datepicker__day--selected {
    background: rgba(204, 204, 204, 0.258824);
    border-color: #707070 !important;
  }
}
/* end of custom calendar  */

@media (min-width: 380px) {
  /* start of custom calendar  */
  .react-datepicker {
    .react-datepicker__header {
      padding: 16px;
    }

    .react-datepicker__day-names {
      margin-top: 16px;
      .react-datepicker__day-name {
        padding: 8px 16px;
      }
    }

    .react-datepicker__month {
      padding: 16px;

      .react-datepicker__week {
        .react-datepicker__day {
          padding: 8px 16px;
        }
      }
    }
  }
  /* end of custom calendar  */
}

::-webkit-scrollbar {
  width: 0;
}

// loader for application

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: grid;
  place-items: center;
  background: rgba(228, 228, 228, 0.2);
  backdrop-filter: blur(4.5px);
}

.accordion-button:not(.collapsed) {
  background: white;
  color: #707070;
  outline: none;
}
.accordion-button:focus {
  box-shadow: none !important;
  outline: none;
}
