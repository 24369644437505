@import '../../../styles/variables';
.basicDetailsMainContainer {
  // padding-bottom: 64px;
  .headerContainer {
    .titleContainer {
      font-size: unit(18px);
      font-weight: 700;
    }
  }

  .formMainContainer {
    margin-top: 16px;
  }
}

@media (min-width: $width768) {
  .basicDetailsMainContainer {
    padding-bottom: 0;
    .headerContainer {
      .formMainContainer {
        margin-top: 32px;
      }
      .titleContainer {
        font-size: unit(24px);
      }
    }
  }
  
}
