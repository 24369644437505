@import '../../styles/variables';

.containerDisabled {
  opacity: 0.6;
}
.disabled {
  cursor: not-allowed !important;
}

.dropDownBtn {
  background: transparent;
  border: 0;
  color: $black50;
  font-weight: 500;
  font-size: unit(14px);
  padding: 0;

  .dropDownArrow {
    width: 16px;
  }
}

.dropDown {
  position: relative;

  .dropDownBtnContainer {
    // white-space: nowrap;
    background: transparent;
    border-radius: 30px;
    padding: 8px 16px;

    .dropDownBtn {
      &:nth-child(2) {
        margin-left: 16px;
      }
    }

    .inputField {
      margin-right: 16px;
      background: white;
      padding: 4px 16px;
    }
  }

  .dropDownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    // width: 100%;
    // min-width: 250px;
    background: white;
    box-shadow: 6px 12px 44px rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    z-index: 999;

    .dropDownItem {
      background: transparent;
      border: 0;
      display: block;
      width: 100%;
      text-align: left;
      padding: 16px;
      color: $gray50;
      font-weight: 500;
      font-size: unit(14px);

      &:not(:last-child) {
        border-bottom: 1px solid lighten($black50, 50%);
      }

      &.activeItem {
        color: $green100;
      }
    }
    .dropDownItem:hover {
      color: $green100;
    }
  }
}
