@import '../../styles/variables';
.removeButton {
  > div {
    font-weight: 700;
    font-size: unit(12px);
    &:nth-child(1) {
      width: 16px;
      margin-right: 8px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.numberInputLabelContainer {
  > div {
    background: $gray25;
    font-size: unit(14px);
    font-weight: 500;
    color: $black75;
    padding: 4px 16px;
    border-radius: 30px;
    flex: 0 0 48%;
    max-width: 48%;
  }
  .flexChildInputContainer {
    > div {
      .inputField {
        background: transparent;
      }
    }
  }
}

.flexChild {
  border-radius: 30px;
  padding: 8px;
  margin-bottom: 8px;
  background: white;

  > div {
    &:nth-child(1) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 8px;
      font-weight: 500;
    }

    &:nth-child(2) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: auto;
      border-radius: 30px;
    }
  }
}

.servicePriceContainer {
  background: $gray25;
  border-radius: 14px;
  padding: 8px;

  .serviceNameContainer {
    > div {
      &:first-child {
        width: 100px;
      }
    }
    margin-bottom: 16px;
    padding-left: 16px;
    .serviceName {
      font-weight: 700;
      width: calc(100% - 90px);
    }

    .btnContainer {
      width: 90px;
      opacity: 0.5;
    }
  }

  .serviceDetailsContainer {
    > div {
      &:not(:first-child) {
        // background: white;
      }
      &:nth-child(1),
      &:nth-child(5) {
        > div {
          &:nth-child(2) {
            // background: $gray25;
          }
        }
      }
      &:nth-child(3) {
        > div {
          &:nth-child(2) {
            > div {
              &:nth-child(2) {
                > div {
                  input {
                    cursor: not-allowed;
                  }
                }
              }
            }
          }
        }
      }

      &:last-child {
        margin-top: 16px;
        > div {
          &:nth-child(2) {
            background: $gray25;
          }
        }
      }
    }
  }
}

.vatColumnContainer {
  display: none;
}

.workShareColumn {
  background: white;
  border-radius: 30px;
  padding: 8px;
  > div {
    background: transparent;
    padding: 0;
    > div {
      &:nth-child(2) {
        background: $gray25;
      }
    }
  }
}

.quantityContainer {
  background: white;
  border-radius: 30px;
  padding: 8px;
  margin-bottom: 8px;
  > div {
    &:nth-child(1) {
      margin-bottom: 8px;
      > div {
        flex: 0 0 30%;
        max-width: 30%;
        &:nth-child(2) {
          flex: 0 0 70%;
          max-width: 70%;
          > div {
            background: $gray25;
            padding: 0;
          }
        }
      }
    }

    &:nth-child(2) {
      > div {
        flex: 0 0 30%;
        max-width: 30%;
        &:nth-child(2) {
          flex: 0 0 70%;
          max-width: 70%;
          > div {
            background: $gray25;
            padding: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .quantityContainer {
    > div {
      &:nth-child(1) {
        // margin-bottom: 8px;
        > div {
          flex: 0 0 40%;
          max-width: 40%;
          &:nth-child(2) {
            flex: 0 0 60%;
            max-width: 60%;
            // > div {
            //   background: $gray25;
            //   padding: 0;
            // }
          }
        }
      }

      &:nth-child(2) {
        > div {
          flex: 0 0 40%;
          max-width: 40%;
          &:nth-child(2) {
            flex: 0 0 60%;
            max-width: 60%;
            // > div {
            //   background: $gray25;
            //   padding: 0;
            // }
          }
        }
      }
    }
  }

  .flexChild {
    border-radius: 18px;
    padding: 8px;

    > div {
      &:nth-child(2) {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
}

@media (min-width: $width576) {
  .vatColumnContainer {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: unit(16px);
    > div {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
        > div {
          width: 48%;
          padding-left: 16px;
        }
      }
    }
  }
  .flexChild {
    border-radius: 30px;

    > div {
      &:nth-child(1) {
        flex: 0 0 40%;
        max-width: 40%;
        margin-bottom: 0;
      }

      &:nth-child(2) {
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 0;
      }
    }
  }

  .serviceDetailsContainer {
    > div {
      &:nth-child(4) {
        > div {
          &:nth-child(1) {
            flex: 0 0 40%;
            max-width: 40%;
          }
          &:nth-child(2) {
            flex: 0 0 60%;
            max-width: 60%;
            > div {
              &:nth-child(1) {
                background: transparent;
              }
            }
          }
        }
      }

      &:nth-child(5) {
        > div {
          &:nth-child(2) {
            > div {
              &:nth-child(1) {
                > div {
                  &:nth-child(1) {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .quantityContainer {
    > div {
      &:nth-child(1) {
        margin-bottom: 0;
        flex: 0 0 40%;
        max-width: 40%;
        > div {
          flex: 0 0 40%;
          max-width: 40%;
          &:nth-child(2) {
            flex: 0 0 60%;
            max-width: 60%;
            // > div {
            //   background: $gray25;
            //   padding: 0;
            // }
          }
        }
      }

      &:nth-child(2) {
        flex: 0 0 60%;
        max-width: 60%;
        padding-left: 16px;
        > div {
          flex: 0 0 50%;
          max-width: 50%;
          &:nth-child(2) {
            flex: 0 0 50%;
            max-width: 50%;
            // > div {
            //   background: $gray25;
            //   padding: 0;
            // }
          }
        }
      }
    }
  }

  .workShareColumn {
    > div {
      > div {
        &:nth-child(1) {
          flex: 0 0 71%;
          max-width: 71%;
        }
        &:nth-child(2) {
          flex: 0 0 29%;
          max-width: 29%;
        }
      }
    }
  }
}

// @media (min-width: $width768) {
//   .flexChild {
//     border-radius: 30px;

//     > div {
//       &:nth-child(1) {
//         flex: 0 0 35%;
//         max-width: 35%;
//       }

//       &:nth-child(2) {
//         flex: 0 0 65%;
//         max-width: 65%;
//       }
//     }
//   }

//   .vatColumnContainer {
//     > div {
//       &:nth-child(1) {
//         width: 35%;
//       }
//       &:nth-child(2) {
//         width: 65%;
//       }
//     }
//   }
// }
