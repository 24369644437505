@import '../../styles/variables';

.headerContent {
  display: block;
  .phoneIcon {
    margin-right: 10px;
  }
}

@media (min-width: $width768) {
  .headerContent {
    > div {
      .contentLink {
        display: block;
        width: 16px;
        margin-right: 10px;
      }

      p {
        font-weight: 500;
      }

      &:nth-child(1) {
        margin-right: 32px;
      }
    }
  }
}
