@import '../../../styles/variables';

.introductionTitle {
  font-size: unit(18px);
  font-weight: 700;
  margin-left: 16px;
  margin-bottom: 12px;
}
.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}

.textArea {
  padding: 16px;
}
.offerParagraphDiv {
  .divTitle {
    font-size: unit(18px);
    font-weight: 700;
    margin-left: 16px;
    margin-bottom: 12px;
  }
  .divContent {
    background: $gray25;
    border-radius: 18px;
    padding: 16px;
    font-size: unit(14px);

    &.divContentBullet {
      > div {
        position: relative;
        padding-left: 16px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $black50;
        }
      }
    }
  }

  &.serviceDiv {
    margin-bottom: 0;
    .serviceTitle {
      margin-bottom: 32px;
    }

    > div {
      &:nth-child(2) {
        margin-bottom: 32px;
      }
    }
  }
}

.offerParagraphContainer {
  padding-bottom: 32px;
  .offerParagraphTitle {
    display: none;
  }

  > div {
    margin-bottom: 32px;
  }
}

@media (min-width: $width768) {
  .offerParagraphContainer {
    width: 80%;
    .offerParagraphTitle {
      display: block;
      font-weight: 700;
      font-size: unit(24px);
      margin-bottom: 16px;
      margin-top: 8px;
    }
  }

  @media (min-width: $width768) {
    .offerParagraphContainer {
      width: 60%;
    }
  }
}
