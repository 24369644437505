@import '../../styles/variables';

.appContainer {
  .fixedElement {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 998;
  }

  .footerElement {
    top: auto;
    bottom: 0;
  }

  .projectContent {
    // margin-top: $mobHeaderHeight;
    // height: calc(100vh - #{$mobHeaderHeight+$mobHeaderHeight});
    // height: 100vh;
    // background: rgb(163, 163, 249);
    padding-top: $mobHeaderHeight;
    padding-bottom: $mobHeaderHeight;
    overflow-y: auto;
  }
}

@media (min-width: $width768) {
  .appContainer {
    .projectContent {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: $desktopHeaderHeight;
      height: calc(100vh - #{$desktopHeaderHeight+$desktopHeaderHeight});
    }
  }
}
