@import '../../styles/variables';

.footerContainer {
  display: grid;
  align-content: center;
  height: $mobHeaderHeight;
  border-top: 1px solid $gray25;
  background: white;

  .logoContainer {
    width: 70px;

    .logoBtn {
      border: none;
    }
  }

  .contentContainer {
    width: calc(100% - 70px);
    padding-left: 16px;
  }
}

@media (min-width: $width576) {
  .footerContainer {
    .logoContainer {
      width: auto;
    }

    .contentContainer {
      width: auto;
    }
  }
}

@media (min-width: $width768) {
  .footerContainer {
    height: $desktopHeaderHeight;

    .logoContainer {
      width: auto;
    }

    .contentContainer {
      width: auto;
    }
  }
}
