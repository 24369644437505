@import '../../../styles/variables';
.statusText {
  font-size: unit(16px);
  font-weight: 600;
  margin-top: 5px;
}

.green {
  color: $green100;
}

.red {
  color: $red100;
}

.serviceTextContainer {
  > div {
    &:nth-child(2) {
      background: $gray25;
      padding: 8px;
      border-radius: 30px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}

.mainPageContainer {
  .deviceTitle {
    font-size: unit(16px);
    font-weight: 700;
    margin-bottom: 16px;
  }

  .heatingDeviceContainer {
    margin-bottom: 32px;
    > div {
      > div {
        margin-bottom: 16px;
      }
    }
  }

  .productDeviceContainer {
    margin-bottom: 32px;
    // > div {
    //   > div {
    //     margin-bottom: 0;
    //   }
    // }
  }
}

@media (min-width: $width992) {
  .mainPageContainer {
    .deviceTitle {
      font-size: unit(18px);
      // font-weight: 700;
      // margin-bottom: 16px;
    }

    .heatingDeviceContainer {
      // margin-bottom: 32px;
      > div {
        > div {
          margin-bottom: 16px;
          flex: 0 0 32%;
          max-width: 32%;
        }
      }
    }

    .productDeviceContainer {
      // margin-bottom: 32px;
      > div {
        > div {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }
}
