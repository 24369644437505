@import '../../styles/variables';

.mobTabLinkContainer {
  margin-bottom: 32px;
  height: $mobTabGroupHeight;
  .mobAccordion {
    .mobAccordionItem {
      border: 0;
      background: transparent;
      .mobAccordionHeader {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -16px;
          left: -16px;
          background: $gray25;
          height: 1px;
        }
        button {
          color: $green100;
          font-size: unit(16px);
          font-weight: 600;
          padding: 16px 0;
          box-shadow: none;
          background: transparent;

          &::after {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $gray25;
            background-image: url(../../assets/svgs/ArrowDown.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            color: $black50;
          }
        }
      }
      .mobAccordionBody {
        padding: 0;
        position: relative;
        background: transparent;
        &::after {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: -16px;
          right: -16px;
          background: $gray25;
          z-index: 4;
        }
        .mobTabLinkActive {
          color: $green100;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.3;
        }
        .mobTabLink {
          display: block;
          color: lighten($black50, 30%);
          font-weight: 500;
          font-size: unit(16px);
          padding: 12px 0;
          position: relative;
          z-index: 5;
          &::after {
            content: '';
            position: absolute;
            right: -16px;
            left: -16px;
            bottom: 0;
            height: 1px;
            background: lighten($black50, 40%);
          }
        }
      }
    }
  }
}

.tabLinkContainer {
  border: 0;
  padding: 16px 0;
  // position: relative;
  // margin-bottom: 16px;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   height: 1px;
  //   background: lighten($black50, 50%);
  //   right: -16px;
  //   left: -16px;
  //   bottom: 0;
  // }

  .linkFlexContainer {
    > div {
      &:not(:last-child) {
        border-right: 1px solid lighten($black50, 10%);
      }

      &:first-child {
        .tabLink {
          padding-left: 0;
        }
      }

      .tabLink {
        font-weight: 500;
        color: $black50;
        border: 0;
        border-radius: 0;
        padding: 0;
        padding-right: 6px;
        padding-left: 6px;
        font-size: unit(12px);
        display: block;
        white-space: nowrap;

        &.tabLinkActive {
          color: $green100;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .tabLinkContainer {
    height: $desktopTabGroupHeight;
    .linkFlexContainer {
      > div {
        .tabLink {
          // font-weight: 600;
          // padding-right: 12px;
          // padding-left: 12px;
          font-size: unit(14px);
        }
      }
    }
  }
}

@media (min-width: $width1200) {
  .tabLinkContainer {
    height: $desktopTabGroupHeight;
    .linkFlexContainer {
      > div {
        .tabLink {
          font-weight: 600;
          padding-right: 12px;
          padding-left: 12px;
          font-size: unit(16px);
        }
      }
    }
  }
}
