@import '../../styles/variables';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
