@import '../../styles/variables';
.removeBtn {
  font-size: unit(12px);
  font-weight: 700;
  color: $black50;
  opacity: 0.5;
  .removeBtnIcon {
    width: 16px;
  }
}

.vatContainer {
  display: none;
}

.priceFieldFlex {
  padding: 8px;
  background: white;
  > div {
    font-weight: 500;

    &:nth-child(1) {
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      > div {
        flex: 0 0 50%;
        max-width: 50%;
        &:nth-child(2) {
          background: $gray25;
          border-radius: 18px;
          padding: 0 8px;
          > div {
            &:nth-child(1) {
              .inputField {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.numberFieldFlex {
  background: white;
  .firstColumn {
    font-weight: 500;
    padding-left: 8px;

    > div {
      flex: 0 0 30%;
      max-width: 30%;

      &:nth-child(2) {
        flex: 0 0 70%;
        max-width: 70%;
        > div {
          > div {
            &:nth-child(2) {
              background: $gray25;
            }
          }
        }
      }
    }
  }

  .secondColumn {
    padding-left: 8px;
    font-weight: 500;
    > div {
      flex: 0 0 30%;
      max-width: 30%;

      &:nth-child(2) {
        flex: 0 0 70%;
        max-width: 70%;
        > div {
          > div {
            &:nth-child(2) {
              background: $gray25;
            }
          }
        }
      }
    }
  }
}

.productFieldContainer {
  background: $gray25;
  padding: 8px;
  border-radius: 14px;
  overflow: hidden;

  .headerContainer {
    margin-bottom: 16px;
    > div {
      &:nth-child(1) {
        width: calc(100% - 80px);
        padding-right: 16px;
        word-break: break-all;
        .headerName {
          font-weight: 700;
        }
      }
      &:nth-child(2) {
        width: 80px;
      }
    }
  }

  .contentContainer {
    > div {
      // background: white;
      border-radius: 18px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:nth-child(1) {
        > div {
          &:nth-child(2),
          &:nth-child(3) {
            > div {
              > div {
                background: $gray25;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        > div {
          &:nth-child(2) {
            background: $gray25;
          }
        }
      }
    }
  }
}

@media (min-width: $width576) {
  .vatContainer {
    display: block;
    font-weight: 500;
    font-size: unit(12px);

    > div {
      flex: 0 0 50%;
      max-width: 50%;
      &:nth-child(2) {
        padding-left: 8px;

        > div {
          &:nth-child(1) {
            flex: 0 0 30%;
            max-width: 30%;
          }
          &:nth-child(2) {
            flex: 0 0 70%;
            max-width: 70%;
            padding-left: 12px;
          }
        }
      }
    }
  }
  .numberFieldFlex {
    .firstColumn {
      flex: 0 0 50%;
      max-width: 50%;
      > div {
        flex: 0 0 40%;
        max-width: 40%;

        &:nth-child(2) {
          flex: 0 0 60%;
          max-width: 60%;
          > div {
            > div {
              &:nth-child(2) {
                background: $gray25;
              }
            }
          }
        }
      }
    }

    .secondColumn {
      flex: 0 0 50%;
      max-width: 50%;
      > div {
        flex: 0 0 30%;
        max-width: 30%;

        &:nth-child(2) {
          flex: 0 0 70%;
          max-width: 70%;
          > div {
            > div {
              &:nth-child(2) {
                background: $gray25;
              }
            }
          }
        }
      }
    }
  }

  .priceFieldFlex {
    padding: 0;
    > div {
      &:nth-child(1) {
        margin-bottom: 0;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 8px;
      }

      &:nth-child(2) {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 0;
        padding: 8px;
        > div {
          flex: 0 0 32%;
          max-width: 32%;
          &:nth-child(2) {
            flex: 0 0 68%;
            max-width: 68%;
            background: $gray25;
            border-radius: 18px;
            > div {
              &:nth-child(1) {
                width: 90%;
                .inputField {
                  background: transparent;
                }
              }

              &:nth-child(2) {
                width: 10%;
              }
            }
          }
        }
      }
    }
  }

  .productFieldContainer {
    .contentContainer {
      > div {
        border-radius: 30px;
      }
    }
  }
}

// @media (min-width: $width768) {
// }
