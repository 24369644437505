@import '../../styles/variables';

.attachmentMainContainer {
  margin-top: 32px;

  .flexContainer {
    font-size: unit(18px);
    font-weight: 700;
    margin-bottom: 16px;
    > div {
      &:nth-child(1) {
        width: 10px;
        margin-right: 10px;
      }
    }
  }

  .attachmentContainer {
    background: $gray25;
    padding: 16px;
    border-radius: 18px;

    > div {
      background: white;
      padding: 14px 16px;
      border-radius: 60px;
      color: $green100;
      font-size: unit(14px);
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

@media (min-width: $width768) {
  .attachmentMainContainer {
    margin-top: 0;
  }
}
