@import '../../../../styles/variables';

.additionalInformationContainer {
  margin-top: 64px;
  .technicalTitle {
    font-weight: 700;
    font-size: unit(18px);
    margin-bottom: 16px;
  }

  .additionalInformationContent {
    .inputContainer {
      margin-bottom: 32px;
      > div {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    .textAreaContainer {
      .textArea {
        background: $gray25;
        border-radius: 18px;
        padding: 16px;
      }
    }
  }
}

@media (min-width: $width768) {
  .additionalInformationContainer {
    .additionalInformationContent {
      .inputContainer {
        margin-bottom: 0;
        flex: 0 0 50%;
        max-width: 50%;
        margin-right: 32px;
      }

      .textAreaContainer {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
}
