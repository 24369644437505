@import '../../styles/variables';

.itemContentTextArea {
  background: white;
  padding: 8px;
  // border-radius: 30px;
}

.offerSummaryContainer {
  .offerSummaryTitleContainer {
    margin-bottom: 16px;
    .offerSummaryTitle {
      font-weight: 700;
      font-size: unit(18px);
    }
  }

  .summaryContainer {
    background: $gray25;
    border-radius: 14px;
    padding: 16px;

    &.mobileSummary {
      .headerText {
        font-size: unit(14px);
        font-weight: 600;
        border-bottom: 1px solid $gray75;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }

      .summaryContent {
        &:not(:last-child) {
          border-bottom: 1px solid $gray75;
          margin-bottom: 16px;
          padding-bottom: 16px;
        }
        .itemName {
          font-weight: 600;
          font-size: unit(14px);
          color: $black75;
          margin-bottom: 16px;
        }

        .itemContent {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
          > div {
            &:nth-child(1) {
              font-weight: 500;
              font-size: unit(12px);
            }

            &:nth-child(2) {
              font-weight: 600;
              font-size: unit(14px);
              color: $black75;
              > span {
                &:nth-child(2) {
                  font-weight: 500;
                  font-size: unit(10px);
                  color: #7e7e7e;
                }
              }
            }
          }

          .itemContentInput {
            > div {
              &:nth-child(1) {
                > div {
                  &:nth-child(2) {
                    input {
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }
            width: 100%;
          }

          &:nth-child(3) {
            > div {
              &:nth-child(2) {
                > div {
                  > div {
                    &:nth-child(2) {
                      background: white;
                    }
                  }
                }
              }
            }
          }
          &:last-child {
            > div {
              &:nth-child(1) {
                margin-bottom: 4px;
              }
            }
          }
        }
      }
    }

    &.desktopSummary {
      .headerContainer {
        font-size: unit(14px);
        font-weight: 600;
      }

      .contentContainer {
        > div {
          font-size: unit(14px);
          font-weight: 500;

          &:nth-child(2) {
            color: $black75;

            > span {
              &:nth-child(2) {
                font-size: unit(10px);
                color: #7e7e7e;
              }
            }
          }

          &:nth-child(3) {
            > div {
              > div {
                &:nth-child(2) {
                  background: white;
                  > input {
                    cursor: not-allowed;
                  }
                  p {
                    right: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .flexContainer {
        padding: 8px 0;
        > div {
          flex: 0 0 26.66%;
          max-width: 26.66%;
          &:nth-child(1) {
            flex: 0 0 20%;
            max-width: 20%;
          }

          &:not(:last-child) {
            border-right: 1px solid lighten($gray75, 10%);
          }

          &:not(:first-child) {
            padding-left: 16px;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid lighten($gray75, 10%);
        }
      }
    }
  }

  .totalText {
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
  }
}
