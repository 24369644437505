@import './../../../styles/variables';
.footerElement {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 998;
}

.mainContainer {
  height: calc(100vh - #{$mobHeaderHeight+$mobTabGroupHeight+32px});
  overflow-y: auto;
  padding-bottom: $mobHeaderHeight + 16px;
  // background: rgb(228, 249, 38);
}

@media (min-width: $width768) {
  .mainContainer {
    height: calc(100vh - #{$desktopHeaderHeight+$desktopTabGroupHeight});
    padding-bottom: $desktopHeaderHeight;
  }
}
