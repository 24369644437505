@import '../../../styles/variables';

.formContainer {
  .siteMemoHeaderContainer {
    background: $gray25;
    border-radius: 14px;
    overflow: hidden;

    .headerTab {
      padding: 8px 16px;
      font-weight: 600;

      &:not(:last-child) {
        border-bottom: 1px solid $gray75;
      }

      &.activeTab {
        background: lighten($green100, 40%);
        color: $green100;
      }
    }
  }
}

@media (min-width: $width768) {
  .formContainer {
    .siteMemoHeaderContainer {
      width: 350px;
    }
  }
}
