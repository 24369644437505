@import '../../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }

    .closeBtn {
      padding: 8px 16px;
      font-size: unit(14px);
      font-weight: 500;
    }
  }
}
.attachFileContainer {
  // max-width: 40%;
  .attachFileTitleContainer {
    margin-bottom: 16px;

    .attachFileTitle {
      font-weight: 700;
      font-size: unit(18px);
    }
  }
}

.offerPreviewContainer {
  // padding-bottom: 64px;
  .headerContainer {
    .titleContainer {
      font-size: unit(18px);
      font-weight: 700;
    }
  }

  .formMainContainer {
    margin-top: 32px;

    > div {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      &:last-child {
        margin-top: auto;
        .calculationContainer {
          > div {
            font-size: unit(14px);
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media (min-width: $width576) {
  .btnContainer {
    > div {
      .closeBtn {
        padding: 8px 32px;
        font-size: unit(16px);
        font-weight: 600;
      }
    }
  }
}
@media (min-width: $width768) {
  .offerPreviewContainer {
    padding-bottom: 0;
    .headerContainer {
      .titleContainer {
        font-size: unit(24px);
      }
    }
    .formMainContainer {
      > div {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
