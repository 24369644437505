@import '../../../styles/variables';

.workerBtnContainer {
  > div {
    white-space: nowrap;
    > div {
      margin-bottom: 8px;
      margin-right: 8px;
      div {
        display: block !important;
      }
    }
  }
  .removeBtn {
    font-size: unit(12px);
    font-weight: 500;
    > div {
      &:nth-child(1) {
        width: 16px;
        margin-right: 8px;
      }
    }
  }
}

.assignWorkerPage {
  .assignHeader {
    margin: 32px 0 16px;
    .headerTitleContainer {
      .headerTitle {
        font-size: unit(16px);
        font-weight: 700;
      }
    }

    > div {
      &:nth-child(1) {
        margin-bottom: 16px;
      }
      &:nth-child(2) {
        margin-bottom: 16px;
      }
      &:nth-child(3) {
        font-size: unit(16px);
        font-weight: 500;
      }
    }
  }

  .headerContentContainer {
    margin-top: 32px;
    > div {
      margin-right: 8px;
      > div {
        display: block;
      }
    }
  }

  .selectWorkerContainer {
    margin-top: 32px;
    .titleContainer {
      margin-bottom: 32px;
      .workerTitle {
        font-size: unit(16px);
        font-weight: 700;
      }
    }
  }
}

@media (min-width: $width576) {
  .assignWorkerPage {
    .assignHeader {
      > div {
        &:nth-child(1) {
          margin-bottom: 0;
          margin-right: 16px;
        }
        &:nth-child(2) {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
    .workerContentContainer {
      > div {
        margin-right: 16px;
      }
    }
  }
}

@media (min-width: $width768) {
  .assignWorkerPage {
    .assignHeader {
      .headerTitleContainer {
        .headerTitle {
          font-size: unit(24px);
        }
      }

      > div {
        &:nth-child(1) {
          margin-right: 64px;
        }
        &:nth-child(2) {
          margin-right: 16px;
        }
      }
    }

    .selectWorkerContainer {
      .titleContainer {
        .workerTitle {
          font-size: unit(24px);
        }
      }
    }
  }
}
