@import '../../../styles/variables';

.mainContainer {
  height: 100%;
  overflow-y: auto;
  padding-bottom: $mobHeaderHeight + 16px;
}
.footerElement {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 998;
  .footerContainer {
    display: grid;
    align-content: center;
    height: $mobHeaderHeight;
    border-top: 1px solid $gray25;
    background: white;

    .contentContainer {
      width: calc(100% - 70px);
      padding-left: 16px;
      > div {
        > p {
          color: black;
          &:first-child {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  > div {
    > div {
      > div {
        width: auto !important;
        max-width: 100%;
        img {
          display: block !important;
        }

        &.btnContainer {
          flex: 0 0 auto !important;
          max-width: 100% !important;

          button {
            padding-left: 16px !important;
            padding-right: 16px !important;
          }
        }
      }
    }
  }
}

.customerOfferViewContainer {
  margin-top: $mobHeaderHeight;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 480px) {
  .headerContainer {
    > div {
      > div {
        > div {
          &.btnContainer {
            button {
              padding-left: 32px !important;
              padding-right: 32px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $width576) {
  .footerElement {
    .footerContainer {
      .contentContainer {
        width: auto;
      }
    }
  }
}
@media (min-width: $width768) {
  .customerOfferViewContainer {
    margin-top: $desktopHeaderHeight;
  }
  .footerElement {
    .footerContainer {
      height: $desktopHeaderHeight;

      .contentContainer {
        width: auto;
      }
    }
  }
}
