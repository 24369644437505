@import '../../styles/variables';

.whiteBackgroundInput {
  > div {
    &:first-child {
      > div {
        &:nth-child(2) {
          background: white;
        }
      }
    }
  }
}

.customerInfoContainer {
  margin-bottom: 16px;
  .flexContainer {
    background: $gray25;
    border-radius: 30px;
    padding: 8px;
    color: $black50;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.formMainContainer {
  .imgContainer {
    margin-bottom: 16px;
  }
  .formTitleContainer {
    margin-bottom: 16px;
    .formTitle {
      font-weight: 700;
      color: $black50;
      font-size: unit(18px);
    }
  }

  .formContentMainContainer {
    .formContentContainer {
      margin-bottom: 16px;
      > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .signBtnMainContainer {
    .signBtnContainer {
      .btnContainer {
        margin-bottom: 16px;
        .signBtn {
          background: $green100;
          border-radius: 30px;
          padding: 8px 16px;
          color: white;
          .signBtnIcon {
            margin-left: 16px;
            width: 16px;
            fill: white;
          }
        }

        .submitBtn {
          background: $green100;
          color: white;
          border-radius: 30px;
          padding: 8px 16px;
        }
      }

      .signEditButton {
        fill: $green100;
        width: unit(18px);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .reportMainContainer {
    .noteContainer {
      color: $black50;
      margin-bottom: 16px;
    }

    .noteBtnContainer {
      margin-bottom: 16px;
    }

    .contactDetailsContainer {
      .contactFlexContainer {
        > div {
          flex: 0 0 50%;
          max-width: 50%;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      a.contactFlexContainer {
        color: $black75;

        &:hover {
          color: $green100;
        }
      }
    }
  }
}

.signPreviewLabel {
  padding: 10px 0;
}
