@import '../../../styles/variables';

.attachmentContainer {
  margin-top: 32px;
  .imgContainer {
    margin-bottom: 32px;
    .attachmentImg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  .modalImage {
    max-width: 100%;
    min-width: 95%;
    max-height: 100%;
    object-fit: contain;
  }
}

@media (min-width: 480px) {
  .attachmentContainer {
    .flexContainer {
      display: flex;
      justify-content: space-between;
      .imgContainer {
        flex: 0 0 48%;
        max-width: 48%;
        .attachmentImg {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}

@media (min-width: $width576) {
  .attachmentContainer {
    .flexContainer {
      justify-content: flex-start;
      .imgContainer {
        // margin-bottom: 0;
        margin-right: 32px;
        flex: 0 0 200px;
        max-width: 100%;
        .attachmentImg {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}
