@import '../../styles/variables';

// .projectCalendar {
//   position: relative;
// }
.saveSyncBtnContainer {
  background: white;
  padding: 16px;
  .saveSyncBtn {
    width: 80%;
    margin: 0 auto;
    background: $green100;
    color: white;
    padding: 16px 0;
    font-size: unit(12px);
    font-weight: 600;
    border-radius: 30px;
  }
}

.calendarInputIconContainer {
  background: white;
  border-radius: 30px;
  padding: 0;
  // position: relative;

  .inputContainer {
    width: calc(100% - 24px);
    .inputField {
      background: transparent;
      color: $black75;
      font-weight: 500;
      font-size: unit(14px);

      &.disabledInput {
        cursor: inherit;
      }
    }
  }

  .iconContainer {
    width: 24px;
  }
}

.calendarArrowContainer {
  .btnContainer {
    width: 16px;
  }

  .titleContainer {
    font-size: unit(24px);
    color: $green100;
    font-weight: 700;
  }
}
.event {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: orange;
  border-radius: 50%;
}

@media (min-width: $width768) {
  .saveSyncBtnContainer {
    padding: 32px 64px;
    .saveSyncBtn {
      width: 100%;
      margin: 0;
    }
  }
}
