@import '../../../styles/variables';

.radioControlContainer {
  background: $gray25;
  border-radius: 18px;
  padding: 8px;

  > div {
    &:nth-child(1) {
      margin-bottom: 14px;

      p {
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      > div {
        &:nth-child(1) {
          flex: 0 0 30%;
          max-width: 30%;
        }

        &:nth-child(2) {
          flex: 0 0 70%;
          max-width: 70%;
          position: relative;

          .inputField {
            background: white;
            padding-left: 16px;
            font-size: unit(14px);
            font-weight: 500;
            color: $black75;
          }

          .centimeter {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
            font-size: unit(14px);
            font-weight: 500;
            color: $black75;
          }

          > div {
            &:nth-child(1) {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $width1200) {
  .radioControlContainer {
    // border-radius: 30px;

    > div {
      // &:nth-child(1) {
      //   flex: 0 0 40%;
      //   max-width: 40%;
      //   margin-bottom: 0;
      // }

      &:nth-child(2) {
        width: 80%;
        margin-left: auto;

        > div {
          &:nth-child(1) {
            flex: 0 0 35%;
            max-width: 35%;

            // > div {
            //   &:nth-child(1) {
            //     margin-right: 16px;
            //   }
            // }
          }

          &:nth-child(2) {
            flex: 0 0 65%;
            max-width: 65%;
          }
        }
      }
    }
  }
}
