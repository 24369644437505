@import '../../../styles/variables';

.loginMainContainer {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: $white75;
  display: grid;
  align-content: center;

  .bgImgContainer {
    position: absolute;

    svg {
      width: 100%;
      height: auto;
      display: block;
    }

    &.topLeftImg,
    &.topRightImg {
      top: 0;
    }

    &.topLeftImg {
      left: 0;
      width: 160px;
    }

    &.topRightImg,
    &.middleLeftImg,
    &.middleRightImg,
    &.bottomLeftImg {
      display: none;
    }

    &.bottomRightImg {
      bottom: 0;
      right: 0;
      width: 220px;

      .blendMode {
        mix-blend-mode: multiply;
      }
    }
  }

  .logInContainer {
    z-index: 9999; // this will always content in front instead of images and logoes
    .logoContainer {
      width: 224px;
      margin-bottom: 32px;
    }

    .logInContentContainer {
      width: 324px;
      padding: 32px 16px;
      background: white;
      box-shadow: 0px 32px 74px rgba(0, 0, 0, 0.0509804);
      border-radius: 14px;

      .pageTitle {
        font-size: unit(24px);
        font-weight: 700;
        color: $black100;
        text-align: center;
      }

      .logInForm {
        margin-top: 16px;
        text-align: center;

        ::placeholder {
          font-size: unit(16px);
          font-weight: 500;
          color: $c5c5c5;
        }

        .formGroup {
          margin-bottom: 16px;
        }

        .inputField {
          background: $white75;
          padding: 16px;

          &:focus {
            box-shadow: none;
          }

          &:hover {
            border-color: $green100;
            &::placeholder {
              color: $green100;
            }
          }
        }

        .forgotPassword {
          margin-top: 16px;
          display: block;
          color: inherit;
          font-size: unit(14px);
        }
        .forgotPassword:hover {
          color: $green100;
        }

        .logInFormBtnContainer {
          position: relative;
          z-index: 9999;
          margin: 32px auto 0;
          width: 140px;
        }
      }
    }
  }

  .logInFormDec {
    display: none;
  }
}

@media (min-width: $width768) {
  .loginMainContainer {
    .logInContainer {
      .logInContentContainer {
        width: 503px;
        padding: 32px 48px;
      }
    }
  }
}

@media (min-width: $width992) {
  .loginMainContainer {
    .bgImgContainer {
      position: absolute;

      &.topLeftImg {
        width: 25%;
      }

      &.topRightImg,
      &.middleLeftImg,
      &.middleRightImg,
      &.bottomLeftImg {
        display: block;
      }

      &.bottomRightImg {
        width: 25%;
      }

      &.topRightImg {
        width: 25%;
        right: 0;
      }

      &.middleLeftImg {
        left: 15%;
        bottom: 25%;
        width: 70px;
      }

      &.middleRightImg {
        right: 3%;
        top: 30%;
        width: 70px;
      }

      &.bottomLeftImg {
        width: 25%;
        bottom: 0;
        left: 0;
      }
    }

    .logInFormDec {
      display: block;
      position: absolute;
      left: 50%;
      bottom: 64px;
      transform: translateX(-50%);
      font-size: unit(18px);
      font-weight: 500;
      color: $c5c5c5;
    }

    .logInContainer {
      .logInContentContainer {
        width: 503px;
        padding: 32px 48px;
      }
    }
  }
}

@media (min-width: $width1920) {
  .loginMainContainer {
    .bgImgContainer {
      &.topLeftImg {
        width: 515px;
      }

      &.bottomRightImg {
        width: 425px;
      }

      &.topRightImg {
        width: 363px;
      }

      &.middleLeftImg {
        left: 10%;
      }

      &.middleRightImg {
        right: 3%;
        top: 30%;
        width: 70px;
      }

      &.bottomLeftImg {
        width: 381px;
      }
    }
  }
}
