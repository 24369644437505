@import '../../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}

.calculationControls {
  // border-radius: 14px;
  // padding: 16px;
  margin-bottom: 32px;

  > div {
    // &:nth-child(1) {
    //   flex: 0 0 100%;
    //   max-width: 100%;
    //   margin-bottom: 16px;
    //   text-align: center;
    // }

    &:nth-child(2) {
      // flex: 0 0 30%;
      // max-width: 30%;
      background: white;
      // margin: 0 auto;
    }
  }
}

.calculationsContainer {
  .calculationsTitle {
    display: none;
  }
}

@media (min-width: $width768) {
  .calculationsContainer {
    .calculationsTitle {
      display: block;
      font-weight: 700;
      font-size: unit(24px);
      margin-top: 24px;
      margin-bottom: 32px;
    }

    .calculationControls {
      width: 60%;
      // margin-bottom: 32px;
      // > div {
      //   &:nth-child(2) {
      //     flex: 0 0 100%;
      //     max-width: 100%;
      //     margin: 0;
      //   }
      // }
    }
  }
}

@media (min-width: $width992) {
  .calculationsContainer {
    .calculationsTitle {
      display: block;
      font-weight: 700;
      font-size: unit(24px);
      margin-top: 24px;
      margin-bottom: 32px;
    }

    .calculationControls {
      width: 45%;
      // margin-bottom: 32px;
      // > div {
      //   &:nth-child(2) {
      //     flex: 0 0 100%;
      //     max-width: 100%;
      //     margin: 0;
      //   }
      // }
    }
  }
}
