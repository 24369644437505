@import '../../../styles/variables';

.requestTitleContainer {
  margin-bottom: 16px;

  .requestTitle {
    font-size: unit(18px);
    font-weight: 700;
  }
}

.requestContent {
  margin-bottom: 32px;

  > div {
    flex: 0 0 100%;
    max-width: 100%;

    &:nth-child(3),
    &:nth-child(4) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    p {
      font-weight: 500;

      &:nth-child(1) {
        font-size: unit(14px);
      }

      &:nth-child(2) {
        font-size: unit(16px);
        color: $black75;
      }
    }
  }
}

.formContainer {
  .requestDetailsContainer {
  }

  .progressSummaryContainer {
    .progressContainer {
      margin-bottom: 32px;

      .progressTitleContainer {
        margin-bottom: 16px;

        .progressTitle {
          font-size: unit(18px);
          font-weight: 700;
        }
      }

      .progressContent {
        > div {
          padding: 16px;
          margin-bottom: 8px;
          background: $gray25;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: unit(14px);
        }
      }
    }

    .summaryContainer {
      // margin-bottom: 32px;
      .summaryTitleContainer {
        margin-bottom: 16px;

        .summaryTitle {
          font-weight: 700;
          font-size: unit(18px);
        }
      }

      .summaryTextArea {
        background: $gray25;
        border-radius: 18px;
        border: 0;
        width: 100%;
        padding: 16px;

        &:focus {
          outline: 0;
        }
      }
    }
  }
}

@media (min-width: $width768) {
  .requestContent {
    > div {
      flex: 0 0 auto;
      max-width: auto;
      margin-right: 32px;

      &:nth-child(3),
      &:nth-child(4) {
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
  }
  .formContainer {
    .progressSummaryContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;

      .progressContainer,
      .summaryContainer {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: $width1200) {
  .requestContent {
    > div {
      flex: 0 0 20%;
      max-width: 20%;
      margin-right: 0;

      &:nth-child(3),
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }

      &:nth-child(6),
      &:nth-child(7) {
        flex: 0 0 auto;
        max-width: 100%;
      }

      &:nth-child(6) {
        margin-right: 64px;
      }
    }
  }
  // .formContainer {
  //   .requestDetailsContainer {
  //   }
  // }
}
