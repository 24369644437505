@import '../../styles/variables';

.serviceListContainer {
  margin-bottom: 8px;

  .serviceList {
    background: $gray25;
    padding: 16px;
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid transparent;
    display: inline-block;

    .boldText {
      font-weight: 600;
    }

  }

  &.activeList {
    .serviceList {
      background: lighten($green100, 35%);
      color: $green100;
      font-weight: 500;
      border-color: $green100;

      .boldText {
        font-weight: 700;
      }

    }
  }
}

.serviceListMainContainer {
  .serviceListHeader {
    margin-bottom: 16px;

    .headerTitle {
      font-size: unit(18px);
      font-weight: 700;
      text-align: left;
      margin-bottom: 16px;
    }

    .headerDec {
      font-weight: 500;
      text-align: right;
      font-size: unit(12px);
      margin-top: 8px;
    }
  }

  .flexContainer {
    position: relative;

    .btnContainer {
      display: inline-block;
      margin-bottom: 8px;
    }
  }
}

.loadBtn {
  padding: 19px 16px;
}

@media (min-width:$width768) {
  .serviceListContainer {
    margin-bottom: 8px;

    .serviceList {
      padding: 6px 16px;
      display: block;
    }
  }

  .serviceListMainContainer {
    .serviceListHeader {
      margin-bottom: 32px;

      .headerTitle {
        margin-bottom: 0;
      }

      .headerDec {
        margin-top: 0;
      }

      >div {
        margin-right: 8px;

        &:nth-child(2) {
          margin-right: 32px;
        }
      }
    }

    .flexContainer {
      >div {
        margin-right: 16px;

        .btnContainer {}
      }
    }
  }

  .loadBtn {
    padding: 10px 16px;
  }
}