@import '../../../../styles/variables';

.fileUploadColumn {
  .fileUploadMainContainer {
    margin-bottom: 16px;
    .fileUploadContainer {
      margin-bottom: 16px;
      > div {
        width: 100%;
        &:nth-child(1) {
          > div {
            &:nth-child(1) {
              > div {
                &:nth-child(1) {
                  > div {
                    flex: 0 0 48%;
                    max-width: 48%;
                    &:nth-child(1) {
                      button {
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .fileUploadError {
      font-size: unit(12px);
      font-weight: 500;
      color: $red100;
    }
  }

  .fileUploadListMainContainer {
    .fileUploadListContainer {
      background: $gray25;
      border-radius: 30px;
      padding: 8px 16px;
      display: inline-block;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: unit(14px);
      span {
        margin-left: 8px;
      }
    }
  }
}

.technicalInformationContainer {
  margin-top: 64px;

  .technicalTitle {
    font-weight: 700;
    font-size: unit(18px);
    margin-bottom: 16px;
  }

  .contentContainer {
    .inputContainer {
      margin-bottom: 32px;

      > div {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:nth-child(6),
        &:nth-child(7) {
          > div {
            &:nth-child(2) {
              > div {
                &:nth-child(2) {
                  > div {
                    &:nth-child(2) {
                      > div {
                        &:nth-child(2) {
                          background: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .uploadContainer {
      margin-bottom: 32px;
    }

    .textAreaContainer {
      .textArea {
        background: $gray25;
        border-radius: 18px;
        padding: 16px;
      }
    }
  }
}

@media (min-width: $width768) {
  .fileUploadColumn {
    flex: 0 0 50%;
    max-width: 50%;
    .fileUploadMainContainer {
      // margin-bottom: 16px;
      flex: 0 0 48%;
      .fileUploadContainer {
        // margin-bottom: 16px;
        > div {
          flex: 0 0 100%;
          max-width: 100%;
          &:nth-child(1) {
            > div {
              &:nth-child(1) {
                > div {
                  &:nth-child(1) {
                    > div {
                      flex: 0 0 100%;
                      max-width: 100%;
                      &:nth-child(1) {
                        button {
                          height: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // > div {
          width: 100%;
          &:nth-child(1) {
            > div {
              &:nth-child(1) {
                > div {
                  &:nth-child(1) {
                    > div {
                      > div {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .fileUploadError {
        font-size: unit(12px);
        font-weight: 500;
        color: $red100;
      }
    }

    .fileUploadListMainContainer {
      flex: 0 0 48%;
      .fileUploadListContainer {
        background: $gray25;
        border-radius: 30px;
        padding: 8px 16px;
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: unit(14px);
        span {
          margin-left: 8px;
        }
      }
    }
  }

  .technicalInformationContainer {
    .contentContainer {
      .inputContainer {
        flex: 0 0 50%;
        max-width: 50%;
        margin-right: 32px;
        margin-bottom: 32px;
        order: 0;
      }

      .uploadContainer {
        order: 2;
        margin-bottom: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .textAreaContainer {
        order: 1;
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
}
