@import '../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}
.productListContainer {
  margin-top: 32px;
  .headerTitle {
    font-size: unit(18px);
    font-weight: 700;
    margin-bottom: 16px;
  }

  .productFlexContainer {
    > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: $width768) {
  .productListContainer {
    .productFlexContainer {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // column-gap: 16px;
      > div {
        margin-bottom: 16px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .productListContainer {
    .productFlexContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      > div {
        margin-bottom: 16px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
