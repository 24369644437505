@import '../../../styles/variables';

.progressContent {
  > div {
    padding: 16px 16px 16px 8px;
    margin-bottom: 8px;
    background: $gray25;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: unit(16px);
  }
}

.deliveryTimeContainer {
  .headerContainer {
    margin-bottom: 16px;
    .headerTitle {
      font-size: unit(18px);
      font-weight: 700;
    }
  }
}

@media (min-width: $width576) {
  .deliveryTimeContainer {
    width: 80%;
  }
}

@media (min-width: $width768) {
  .deliveryTimeContainer {
    width: 60%;
    .headerContainer {
      display: block;
      margin-top: 32px;
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
@media (min-width: $width992) {
  .deliveryTimeContainer {
    width: 50%;
    .headerContainer {
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
@media (min-width: $width1440) {
  .deliveryTimeContainer {
    width: 45%;
  }
}
@media (min-width: $width1920) {
  .deliveryTimeContainer {
    width: 40%;
  }
}
