@import '../../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}
.invoiceMainContainer {
  .titleContainer {
    font-size: unit(18px);
    font-weight: 700;
  }

  .invoiceItemContainer {
    .deviceMainContainer {
      .itemTitleContainer {
        margin-bottom: 4px;
        margin-left: 16px;

        .title {
          font-size: unit(18px);
          font-weight: 700;
        }
      }

      > div:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
      }

      .deviceContainer {
        background-color: $gray25;
        border-radius: 14px;
        padding: 8px;
        cursor: pointer;
        > div {
          &:nth-child(1) {
            > div {
              &:nth-child(2) {
                > div {
                  &:first-child {
                    > div {
                      &:last-child {
                        > input {
                          text-align: end;
                          padding-right: 60px;
                          background-color: white;
                        }
                      }
                    }
                  }
                  &:nth-child(2) {
                    > div {
                      &:not(:first-child) {
                        > div {
                          &:last-child {
                            > div {
                              > div {
                                input {
                                  text-align: end;
                                  background: white;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.activeCard {
          border: 1px solid;
          border-color: $green100;
          background: lighten($green100, 40%);
        }

        &.disabledCard {
          opacity: 0.5;
          cursor: not-allowed;
        }

        > div {
          &:first-child {
            > div {
              &:nth-child(2) {
                margin-bottom: 0;
                > div {
                  &:nth-child(3) {
                    > div {
                      &:nth-child(2) {
                        > input {
                          background: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .productMainContainer {
      margin: 32px 0;
      .itemTitleContainer {
        margin-bottom: 4px;
        margin-left: 16px;

        .title {
          font-size: unit(18px);
          font-weight: 700;
        }
      }
      > div:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
      }

      .productContainer {
        cursor: pointer;

        > div {
          > div {
            &:nth-child(2) {
              > div {
                &:nth-child(1) {
                  > div {
                    &:nth-child(1) {
                      > div {
                        &:nth-child(2) {
                          input {
                            text-align: end;
                            background: white;
                          }
                          p {
                            right: 16px;
                          }
                        }
                      }
                    }
                    &:nth-child(2) {
                      > div {
                        > div {
                          background: white;
                          input {
                            background: white;
                            text-align: end;
                          }
                          p {
                            right: 4px;
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-child(2) {
                  > div {
                    &:nth-child(2) {
                      background: transparent;
                      > div {
                        &:nth-child(2) {
                          background: white;
                          > div {
                            > input {
                              text-align: end;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &:nth-child(3) {
                  > div {
                    &:nth-child(2) {
                      background-color: transparent;
                      > div {
                        &:nth-child(2) {
                          background: white;
                          > div {
                            > input {
                              text-align: end;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.activeCard {
          > div {
            border: 1px solid;
            border-color: $green100;
            background: lighten($green100, 40%);
          }
        }

        &.disabledCard {
          > div {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    .serviceMainContainer {
      .itemTitleContainer {
        margin-bottom: 4px;
        margin-left: 16px;

        .title {
          font-size: unit(18px);
          font-weight: 700;
        }
      }
      > div:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
      }

      .serviceContainer {
        cursor: pointer;

        > div {
          > div {
            > div {
              &:nth-child(2) {
                > div {
                  &:nth-child(1) {
                    > div {
                      input {
                        background: white;
                      }
                      &:nth-child(2) {
                        background: white;
                        > div {
                          background: white;
                          > div {
                            background: white;
                            > div {
                              background: white;
                              > div {
                                background: white !important;
                                > div {
                                  background: white;
                                  > input {
                                    text-align: end;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:nth-child(2) {
                    > div {
                      &:nth-child(2) {
                        > input {
                          background: white;
                          text-align: end;
                          padding-right: 67px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.activeCard {
          > div > div {
            border: 1px solid;
            border-color: $green100;
            background: lighten($green100, 40%);
          }
        }

        &.disabledCard {
          > div > div {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
@media (min-width: $width768) {
  .invoiceMainContainer {
    .invoiceItemContainer {
      .deviceMainContainer {
        .deviceContainer {
          > div {
            &:nth-child(1) {
              > div {
                &:nth-child(2) {
                  > div {
                    &:first-child {
                      > div {
                        &:last-child {
                          > input {
                            text-align: end;
                            padding-right: 32px;
                          }
                        }
                      }
                    }
                    &:nth-child(2) {
                      > div {
                        &:not(:first-child) {
                          > div {
                            &:last-child {
                              > div {
                                > div {
                                  input {
                                    text-align: end;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $width1200) {
  .invoiceMainContainer {
    padding-bottom: 0;

    .titleContainer {
      font-size: unit(24px);
    }

    .invoiceItemContainer {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;

      .productMainContainer {
        margin: 0;
      }

      .serviceMainContainer {
        margin-top: 32px;
      }
    }
  }
}
