@import '../../../styles/variables';

.itemInformationContainer {
  .headerContainer {
    display: none;
    .headerTitle {
      font-weight: 700;
      font-size: unit(16px);
    }
  }

  .contentContainer {
    > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:first-child {
        > div {
          &:nth-child(2) {
            background: white;
          }
        }
      }
    }

    .sizeOfInputContainer {
      input {
        width: 70%;
        float: right;
      }
    }

    .errorLabel {
      color: red;
      padding: 5px;
      font-size: 15px;
    }
  }
}

@media (min-width: $width768) {
  .itemInformationContainer {
    width: 70%;
    .headerContainer {
      display: block;
      margin-top: 32px;
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
@media (min-width: $width992) {
  .itemInformationContainer {
    width: 60%;
    .headerContainer {
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
@media (min-width: $width1200) {
  .itemInformationContainer {
    width: 55%;
  }
}
