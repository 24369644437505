@import '../../styles/variables';

.uploadFileContainer {
  background: $gray25;
  border-radius: 18px;
  padding: 16px;
}

.uploadBtnContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  display: grid;
  align-content: center;
  border-radius: 8px;
  // margin-bottom: 16px;
  .btnContainer {
    width: 100%;
  }
}

.uploadBtnContainer .uploadBtn {
  color: inherit;
  background-color: white;
  border-radius: 8px;
  padding: 16px;

  .uploadIcon {
    width: 45px;
    margin-right: 8px;
  }

  .uploadText {
    font-size: unit(14px);
    font-weight: 500;
    margin-top: 5px;
    text-align: center;
  }
}

.uploadBtnContainer input[type='file'] {
  font: inherit;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.uploadBtnContainer input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

.uploadedFileContainer {
  max-height: 300px;
  overflow-y: auto;
  .mainContainer {
    padding: 16px;
    background-color: white;
    border-radius: 40px;
    margin-top: 16px;
    display: inline-block;
    .uploadedFileItem {
      .uploadedFileItemNameContainer {
        margin-right: 20px;
        word-break: break-all;
        p {
          color: $green100;
        }
      }
      .trashBtnContainer {
        cursor: pointer;
        width: 20px;
      }
    }
  }
}
