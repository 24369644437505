@import '../../../styles/variables';

.errorLabel {
  color: red;
  padding: 5px;
  font-size: 15px;
}

.addressColumn {
  > div {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.customerInformationContainer {
  // padding-top: 32px;
  // padding-bottom: 64px;
  .headerContainer {
    display: none;
    margin-top: 32px;
    .headerTitle {
      font-weight: 700;
      font-size: unit(16px);
    }
  }

  .contentContainer {
    > div {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .addressContainer {
    margin-top: 16px;
    .addressContent {
      margin-top: 16px;
      .headerTitle {
        margin-bottom: 16px;
      }
    }
  }
}

@media (min-width: $width576) {
  .addressColumn {
    > div {
      &:nth-child(1) {
        flex-wrap: wrap;
        max-width: 100%;
        > div {
          max-width: 100%;
          flex: 0 0 100%;

          &:nth-child(1) {
            margin-bottom: 8px;
          }
        }
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: $width768) {
  .addressColumn {
    > div {
      flex: 0 0 48%;
      max-width: 48%;
      &:nth-child(1) {
        flex-wrap: wrap;
        flex: 0 0 100%;
        max-width: 100%;
        > div {
          flex: 0 0 100%;
        }
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
  }
  .customerInformationContainer {
    width: 70%;
    .headerContainer {
      display: block;
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }

    .contentContainer {
      > div {
        flex: 0 0 48%;
        max-width: 48%;
        &:nth-child(5) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .addressContainer {
      .sliderBtnContainer {
        width: 70%;
      }
      .addressContent {
        .headerTitle {
          width: 50%;
          font-size: unit(16px);
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .customerInformationContainer {
    width: 60%;
    .headerContainer {
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}

@media (min-width: $width1200) {
  .customerInformationContainer {
    width: 55%;
  }
}
