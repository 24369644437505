@import '../../../styles/variables';
.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}
.serviceListContainer {
  height: 100%;
  overflow: auto;
  .serviceListTitle {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: unit(18px);
    font-weight: 700;
  }
  .profitCalculation {
    margin-top: auto;
    .calculationContainer {
      > div {
        font-size: unit(14px);
        font-weight: 700;
      }
    }
  }
}
