@import '../../../styles/variables';

.basicDetailsContainer {
  margin: 0;

  .flexContainer {
    > div {
      margin-bottom: 16px;
    }
  }
  .contactContainer {
    margin-bottom: 10px;
    .titleContainer {
      font-size: unit(18px);
      font-weight: 700;
    }
  }
}

@media (min-width: $width768) {
  .basicDetailsContainer {
    margin: 0;

    .flexContainer {
      > div {
        flex: 0 0 48%;
        max-width: 48%;
        // margin-bottom: 16px;
      }
    }
    .contactContainer{
      .titleContainer {
      font-size: unit(24px);
    }
  }
  }
}
