@import '../../../styles/variables';
.flexContainer {
  > div {
    font-weight: 500;
    &:nth-child(1) {
      font-size: unit(16px);
    }

    &:nth-child(2) {
      font-size: unit(14px);
    }
  }
}
.basicDetailsFormContainer {
  .basicDetailsFormTitle {
    font-size: unit(16px);
    font-weight: 700;
    margin-bottom: 16px;
  }
}
