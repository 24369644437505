@import '../../../styles/variables';

.finalOfferPreviewTitle {
  display: none;
}

.finalOfferPreviewContainer {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fff;
    padding: 32px 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  > div {
    &:nth-child(1) {
      .iframeContainer {
        width: 100%;
        height: calc(100vh - 96px);
        font: inherit;
        background: #ffffff;
        box-shadow: 6px 12px 44px rgba(0, 0, 0, 0.09);
      }
    }
  }

  .downloadButtonContainer {
    margin-top: 0.8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .signBtnMainContainer {
    padding: 16px 0;
    > div {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .signBtnContainer {
      .btnContainer {
        .signBtn {
          background: $green100;
          color: white;
          font-weight: 600;
          border-radius: 30px;
          padding: 8px 32px;
          margin-bottom: 16px;
          .signBtnIcon {
            width: 14px;
            fill: white;
            margin-left: 16px;
          }
        }

        .declineBtn {
          background: $red100;
          color: white;
          font-weight: 600;
          padding: 8px 32px;
          border-radius: 30px;
        }
      }
    }
  }
}
@media (min-width: 480px) {
  .finalOfferPreviewContainer {
    .signBtnMainContainer {
      display: flex;
      padding: 16px 0;
      > div {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .signBtnContainer {
        display: flex;
        justify-content: space-between;
        .btnContainer {
          flex: 0 0 48%;
          max-width: 48%;
        }
      }
    }
  }
}

@media (min-width: $width576) {
  .finalOfferPreviewContainer {
    .signBtnMainContainer {
      padding: 16px 20px;
      display: flex;
      > div {
        &:nth-child(1) {
          flex: 0 0 25%;
          display: block;
        }
        &:nth-child(2) {
          flex: 0 0 75%;
          max-width: 75%;
        }
      }
      .signBtnContainer {
        display: flex;
        justify-content: space-between;
        .btnContainer {
          flex: 0 0 48%;
          max-width: 48%;
        }
      }
    }
  }
}

@media (min-width: $width768) {
  .finalOfferPreviewContainer {
    .signBtnMainContainer {
      padding: 16px 20px;
      display: flex;
      > div {
        &:nth-child(1) {
          flex: 0 0 35%;
          max-width: 35%;
          display: block;
        }
        &:nth-child(2) {
          flex: 0 0 65%;
          max-width: 65%;
        }
      }
      .signBtnContainer {
        display: flex;
        justify-content: space-between;
        .btnContainer {
          flex: 0 0 48%;
          max-width: 48%;
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .finalOfferPreviewTitle {
      display: block;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      margin: 16px 0 32px;
      color: #222;
      letter-spacing: 0.5px;
    }
  .finalOfferPreviewContainer {
    > div {
      &:nth-child(1) {
        flex: 0 0 75%;
        max-width: 75%;
      }

      &:nth-child(2) {
        flex: 0 0 25%;
        max-width: 25%;
        padding-left: 32px;
      }
    }

    .signBtnMainContainer {
      padding: 16px 48px;
      > div {
        &:nth-child(1) {
          flex: 0 0 25%;
          max-width: 25%;
          display: block;
        }
        &:nth-child(2) {
          flex: 0 0 75%;
          max-width: 75%;
        }
      }
    }
  }
}
@media (min-width: $width1440) {
  .finalOfferPreviewContainer {
    .signBtnMainContainer {
      padding: 16px 15%;
      > div {
        &:nth-child(1) {
          flex: 0 0 25%;
          max-width: 25%;
          display: block;
        }
        &:nth-child(2) {
          flex: 0 0 75%;
          max-width: 75%;
        }
      }
    }
  }
}
