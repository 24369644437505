@import '../../../styles/variables';

.btnContainer {
  > div {
    &:nth-child(1) {
      margin-right: 16px;
    }
  }
}
.profitCalculation {
  margin-top: auto;
}
.productListMainContainer {
  overflow: auto;
  height: 100%;
}

@media (min-width: $width768) {
  .productListMainContainer {
    height: 100%;
  }
}

@media (min-width: $width992) {
  .productListMainContainer {
    height: 100%;
  }
}
