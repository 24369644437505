$black75: #3e3e3e;
$black50: #707070;
$c5c5c5: #c5c5c5;
$gray50: #95989a;
$gray40: #666666;
$gray75: #c5c5c5;
$gray25: #efefef;
$white75: #f6f6f6;
$white50: #ececec;
$green100: #71bf5d;
$yellow100: #f5c115;
$red100: #e51111;
$red25: #fbdbdb;
$black100: #373737;

// change pixel to rem
@function unit($pixels, $context: 16px) {
  @return calc($pixels / $context) * 1rem;
}

$mobHeaderHeight: 71px;

$desktopHeaderHeight: 80px;

$desktopTabGroupHeight: 56px;

$mobTabGroupHeight: 64px;

// for 1200px device
$containerWidth1040: 1040px;

// for 1440px device
$containerWidth1140: 1140px;

// for 1920px device
$containerWidth1280: 1280px;

// for media quarry
$width576: 576px;
$width768: 768px;
$width992: 992px;
$width1200: 1200px;
$width1440: 1440px;
$width1920: 1920px;
