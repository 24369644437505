@import '../../styles/variables';

.digitalSignatureContainer {
  background: #ffffff;
  box-shadow: 6px 12px 44px rgba(0, 0, 0, 0.09);
  border-radius: 14px;
  overflow: hidden;
  min-height: unset;
  top: 10%;

  .digitalSignatureHeader {
    background: $gray25 !important;
    padding: unset;
    padding-right: 16px !important;
    .customerNameContainer {
      padding: 16px;
      background: white;
      font-size: unit(24px);
      font-weight: 600;
      color: $green100;
      > div {
        &:nth-child(1) {
          width: 16px;
          margin-right: 16px;
        }
      }
    }

    .closeBtn {
      background: white;
      font-weight: 600;
      padding: 8px 32px;
      border-radius: 30px;
      color: #707070;
    }
  }

  .digitalSignatureContent {
    padding: 16px;
    > div {
      &:nth-child(1) {
        color: $gray75;
        font-size: unit(20px);
        font-weight: 500;
      }

      &:nth-child(2) {
        > div {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
@media (min-width: $width576) {
  .digitalSignatureContainer {
    .digitalSignatureContent {
      > div {
        &:nth-child(2) {
          > div {
            &:not(:last-child) {
              margin-bottom: 0;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: $width768) {
  .digitalSignatureContainer {
    width: 80%;
    margin: 0 auto;
    .digitalSignatureContent {
      > div {
        &:nth-child(2) {
          > div {
            &:not(:last-child) {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
