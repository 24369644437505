@import '../../../styles/variables';

.additionalInformationContainer {
  .headerContainer {
    display: none;
    .headerTitle {
      font-weight: 700;
      font-size: unit(16px);
    }
  }

  .contentContainer {
    .timeContainer {
      padding: 8px;
      background-color: $gray25;
      border-radius: 30px;
      .inputField {
        text-align: center;
        outline: none;
        border-radius: 16px;
        border: none;
        background: white;
        padding: 4px 16px;
        font-size: 0.875rem;
        font-weight: 500;
        color: #3e3e3e;
        margin: 0 5px;
      }
      .timeLabel {
        font-weight: 500;
        font-size: unit(16px);
        margin-right: 16px;
      }
      .timeData {
        margin-left: auto;
      }
    }
    > div {
      margin-bottom: 16px;
    }

    .textAreaContainer {
      .textAreaTitle {
        font-size: unit(18px);
        font-weight: 700;
        margin-bottom: 14px;
      }
      .textArea {
        background: $gray25;
        padding: 16px;
      }
    }

    .errorLabel {
      color: red;
      padding: 5px;
      font-size: 15px;
    }
  }
}

@media (min-width: $width576) {
  .additionalInformationContainer {
    width: 80%;
    .contentContainer {
      .timeContainer {
        .inputField {
          padding: 4px 32px;
        }
      }
    }
    // .headerContainer {
    //   display: block;
    //   margin-top: 32px;
    //   .headerTitle {
    //     font-size: unit(24px);
    //     margin-bottom: 32px;
    //   }
    // }
  }
}

@media (min-width: $width768) {
  .additionalInformationContainer {
    width: 60%;
    .headerContainer {
      display: block;
      margin-top: 32px;
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
@media (min-width: $width992) {
  .additionalInformationContainer {
    width: 50%;
    .headerContainer {
      .headerTitle {
        font-size: unit(24px);
        margin-bottom: 32px;
      }
    }
  }
}
// @media (min-width: $width1200) {
//   .additionalInformationContainer {
//     width: 55%;
//   }
// }
@media (min-width: $width1440) {
  .additionalInformationContainer {
    width: 45%;
  }
}
@media (min-width: $width1920) {
  .additionalInformationContainer {
    width: 40%;
  }
}
